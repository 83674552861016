const Button = ({
  parentStyle,
  parentInline,
  inType,
  buttonStyle,
  inline,
  name,
  label,
  id,
  onClick,
}) => {
  return (
    <div className={parentStyle} style={parentInline}>
      <button
        style={inline}
        id={id}
        className={buttonStyle}
        type={inType}
        name={name}
        onClick={onClick}
      >
        {" "}
        {label}
      </button>
    </div>
  );
};

export default Button;
