import Footer from "../footer";
import Header from "../header";
import profileBg from "../../images/profile-bg.png";
import AboutUsSection from "./aboutUsSection";
import Input from "../Tools/Input";
import Button from "../Tools/Button";

const TravelBooking = () => {
  return (
    <>
      <Header />
      <section
        style={{ backgroundImage: `url(${profileBg})` }}
        id="blog-banner"
      >
        <div className="container"></div>
      </section>
      <AboutUsSection />
      <section class="travel-form mt-5 mb-5">
        <div class="container">
          <h1
            class="text-center mb-3"
            style={{ fontSize: "24px", fontWeight: "bold" }}
          >
            {"Contact Us"}
          </h1>
          <div class="row main">
            <Input
              parentStyle={"col-lg-6 col-12 mt-3"}
              labelFor={"first"}
              labelStyle={"w-100"}
              label={"First Name"}
              inType={"text"}
              name={"first_name"}
              inputStyle={"input w-100"}
              inputId={"first"}
              required={true}
            />
            <Input
              parentStyle={"col-lg-6 col-12 mt-3"}
              labelFor={"last"}
              labelStyle={"w-100"}
              label={"Last Name"}
              inType={"text"}
              inputStyle={"input w-100"}
              inputId={"last"}
              name={"last_name"}
              required={true}
            />
            <Input
              parentStyle={"col-lg-6 col-12 mt-3"}
              labelFor={"email"}
              labelStyle={"w-100"}
              label={"Email"}
              inType={"email"}
              inputStyle={"input w-100"}
              inputId={"email"}
              name={"email"}
              required={true}
            />
            <Input
              parentStyle={"col-lg-6 col-12 mt-3"}
              labelFor={"number"}
              labelStyle={"w-100"}
              label={"Phone Number"}
              inType={"number"}
              inputStyle={"input w-100"}
              inputId={"number"}
              name={"email"}
              required={true}
            />
            <Input
              parentStyle={"col-lg-6 col-12 mt-3"}
              labelFor={"address"}
              labelStyle={"w-100"}
              label={"Address"}
              inType={"text"}
              inputStyle={"input w-100"}
              inputId={"address"}
              name={"address"}
              required={true}
            />
            <Input
              parentStyle={"col-lg-6 col-12 mt-3"}
              labelFor={"street"}
              labelStyle={"w-100"}
              label={"Street Number"}
              inType={"text"}
              inputStyle={"input w-100"}
              inputId={"street"}
              name={"street"}
              required={true}
            />
            <Input
              parentStyle={"col-lg-6 col-12 mt-3"}
              labelFor={"zip"}
              labelStyle={"w-100"}
              label={"Zip Code"}
              inType={"text"}
              inputStyle={"input w-100"}
              inputId={"zip"}
              name={"zip"}
              required={true}
            />
            <Input
              parentStyle={"col-lg-6 col-12 mt-3"}
              labelFor={"city"}
              labelStyle={"w-100"}
              label={"City"}
              inType={"text"}
              inputStyle={"input w-100"}
              inputId={"city"}
              name={"city"}
              required={true}
            />
            <Input
              parentStyle={"col-lg-6 col-12 mt-3"}
              labelFor={"city"}
              labelStyle={"w-100"}
              label={"City"}
              inType={"text"}
              inputStyle={"input w-100"}
              inputId={"city"}
              name={"city"}
              required={true}
            />

            <Input
              parentStyle={"col-lg-6 col-12 mt-3"}
              labelFor={"country"}
              labelStyle={"w-100"}
              label={"Country"}
              inType={"text"}
              inputStyle={"input w-100"}
              inputId={"country"}
              name={"country"}
              required={true}
            />

            <div className="col-12 mt-3">
              <p style={{ fontWeight: 600, fontSize: "15px" }}>
                {"Payment Method"}
              </p>
            </div>

            <div className="col-12 mt-3">
              <span className="me-4">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                />
                <label
                  className="form-check-label fw-normal"
                  for="flexRadioDefault1"
                >
                  {"Strike"}
                </label>
              </span>
              <span>
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  checked
                />
                <label
                  className="form-check-label fw-normal"
                  for="flexRadioDefault2"
                >
                  {"Paypal"}
                </label>
              </span>
            </div>

            <Button
              parentStyle={"col-12 mt-3"}
              buttonStyle={"btn mt-3"}
              inType={"submit"}
              label={"Submit"}
            />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default TravelBooking;
