import React from "react";
import { getSingleVoucherApi } from "../../services/api";
import LoaderGif from "../../loader";
const VoucherDetails = ({ id }) => {
  const [data, setData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  React.useEffect(() => {
    getSingleVoucherFun(id);
  }, [id])
  const getSingleVoucherFun = async (id) => {
    setIsLoading(true);
    try {
      const response = await getSingleVoucherApi({ id });
      if (response.data.status) {
        setIsLoading(false);
        setData(response.data.data);
      }
    }
    catch (e) {
      setIsLoading(false);
      console.log("error", e);
    }

  }
  return (<>{isLoading ? <LoaderGif /> : <div>
    <h4>{data && data.title}</h4>
    <p>{data && data.description}</p>
  </div>}</>);
}

export default VoucherDetails;