import { Route, Navigate } from 'react-router-dom';

const AuthRoute = ({ element, isAuthenticated }) => {
console.log("isAuthencited", isAuthenticated)
const loggedInUser = JSON.parse(localStorage.getItem("user"));
  return loggedInUser ? (
    element
  ) : (
    <Navigate to="/login" replace />
  );
};

export default AuthRoute;

