import React from 'react';
import { BsFacebook } from 'react-icons/bs';
import { RiTwitterFill } from 'react-icons/ri';
import { AiOutlineInstagram, AiFillLinkedin } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { specificnews } from '../../services/api';
import Header from '../header/index';
import Footer from '../footer/index';
import moment from 'moment';

const SingleNews = () => {
    const urlParams = new URL(window.location.href).searchParams;
    const [specificNews, setSpecificNews] = React.useState({});
    const subscription = JSON.parse(localStorage.getItem('user')).subscription;
    const navigate = useNavigate();

    if (!urlParams.has("id")) {
        navigate('/home');
    }
    const id = urlParams.getAll('id')[0];
    React.useEffect(() => {
       getSpecificEventById(id);
    }, [setSpecificNews]);

    const getSpecificEventById = async (id) => {
        try
        {
          const response = await specificnews(id);
          setSpecificNews(response.data.data);
        }
        catch(e)
        {
         console.log("error", e.message);
        }
    }

    return (
        <>
            <Header />
            {/* <!-- ///////////-----blog----Section---///////////// --> */}

            <section id="blog-news">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8">
                            <div class="row">

                                <div class="col-md-12">
                                    <div class="bolg-img single">

                                        <img class="w-100" style={{borderRadius: "29px"}} src={`https://digittrix-staging.com/staging/sportsfan/sportsfanworld/public/uploads/${specificNews?.image}`} alt="" />
                                            <h3>{specificNews?.title}</h3>
                                            <p>{subscription ? specificNews?.description : specificNews.description && specificNews.description.substring(0, 200)}</p>
                                            <h6>Post Date: <span>{moment(specificNews?.created_at).format('MMMM D, YYYY')}</span></h6>

                                            <ul>
                                                <li>Share :</li>
                                                <li> <a href="#"><BsFacebook /> </a> </li>
                                                <li> <a href="#"><RiTwitterFill /></a> </li>
                                                <li> <a href="#"><AiOutlineInstagram /></a> </li>
                                                <li> <a href="#"><AiFillLinkedin /></a> </li>
                                            </ul>

                                    </div>
                                </div>



                            </div>
                        </div>

                        <div class="col-lg-4 mt-4">

                            <div class="news-search">
                                <h5>Search</h5>
                                <form class="position-relative">
                                    <input type="text" placeholder="Search..." />
                                        <button class="news-search-btn" type="submit"> <img src={require("../../images/search.png")} alt="" />
                                        </button>
                                </form>
                            </div>

                            <div class="popular-post">
                                <h5>Popular Post</h5>
                                <a href="#">
                                    <img class="w-100" src={require("../../images/upcoming-3.png")}  alt="" />
                                        <p>Football Event</p>
                                        <span>August 4, 2020</span>
                                </a>

                                <div class="b_single pt-2 pb-2 mb-5">
                                    <div class="b_single__thumb">
                                        <img class="w-100" src={require("../../images/profile-man.png")} alt="" />
                                    </div>
                                    <div class="b_single__content">
                                        <a href="#">Top 10 Outdoor Items For Your </a>
                                        <span>August 4, 2020</span>
                                    </div>
                                </div>

                                <div class="b_single pt-2 pb-2 mb-5">
                                    <div class="b_single__thumb">
                                        <img class="w-100" src={require("../../images/profile-man.png")} alt="" />
                                    </div>
                                    <div class="b_single__content">
                                        <a href="#">Top 10 Outdoor Items For Your </a>
                                        <span>August 4, 2020</span>
                                    </div>
                                </div>

                                <div class="b_single pt-2 pb-2 mb-5">
                                    <div class="b_single__thumb">
                                        <img class="w-100" src={require("../../images/profile-man.png")} alt="" />
                                    </div>
                                    <div class="b_single__content">
                                        <a href="#">Top 10 Outdoor Items For Your </a>
                                        <span>August 4, 2020</span>
                                    </div>
                                </div>

                            </div>

                            <div class="category-post">
                                <h5>Categories </h5>
                                <ul>
                                    <li> <a href="#"> Creative <span>(5)</span></a></li>
                                    <li> <a href="#"> Image <span>(4)</span></a></li>
                                    <li> <a href="#"> Sport <span>(3)</span></a></li>
                                    <li> <a href="#"> Video <span>(10)</span></a></li>
                                    <li> <a href="#"> Uncategorized <span>(1)</span></a></li>
                                    <li> <a href="#"> Events <span>(6)</span></a></li>

                                </ul>
                            </div>

                        </div>

                    </div>
                </div>
            </section>




            <Footer />
        </>
    )
}
export default SingleNews;