import { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useNavigate } from "react-router-dom";
import { eventdetails } from "../../services/api";
import moment from "moment";
import axios from "axios";
import Header from "../header";
import Footer from "../footer";
import SimilarEvents from "../events/similarevents";
import VoucherList from "../events/voucher";
import BoostrapModel from "../Tools";
import Subscritpion from "../events/subscription";
import { mediaUrl } from "../../services/content";
import SiteMapSection from "../events/siteMapSection";
import Button from "../Tools/Button";

const SportDetails = () => {
  const urlParams = new URL(window.location.href).searchParams;
  const user = JSON.parse(localStorage.getItem('user'));
  const subscription = JSON.parse(localStorage.getItem("user"))?.subscription;
  const subscription_expire = JSON.parse(localStorage.getItem("user"))?.subscription_expire;
  console.log("subscription", subscription);
  const [specificEvent, setSpecificEvent] = useState({});
  const [show, setShow] = useState(false);
  const [banner, setBanner] = useState([]);
  const [readmore, setReadmore] = useState(false);

  const navigate = useNavigate();
  if (!urlParams.has("id")) {
    navigate("/home");
  }
  const id = urlParams.getAll("id")[0];
  useEffect(() => {
    geteventdetails(id);
  }, [setSpecificEvent]);

  const geteventdetails = async (id) => {
    try {
      const response = await eventdetails(id);
      console.log("response", response);
      setSpecificEvent(response);
      setBanner(response?.image.split(","));
    } catch (e) {
      console.log("error", e.message);
    }
  };

  // const readMoreText = () => {
  //     setReadmore(!readmore);
  // }

  return (
    <>
      <Header />
      <section id="event-slider">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Carousel infiniteLoop={true} showThumbs={false}>
                {banner &&
                  banner.length > 0 &&
                  banner.map((item) => {
                    return (
                      <div
                        style={{ backgroundImage: `url(${mediaUrl}${item})` }}
                        className="event_slider"
                      >
                        {/* <img
                                                className="d-block w-100"
                                               
                                                src={`https://digittrix-staging.com/staging/sportsfan/OldAdmin/sportsfanworld/public/uploads/${item}`}
                                                alt="First slide"
                                            /> */}
                      </div>
                    );
                  })}
              </Carousel>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- ///////////-----Similar Events----Section---///////////// --> */}

      <section id="asian-cup">
        <div className="container">
          <Tabs
            defaultActiveKey="home"
            id="fill-tab-example"
            className="mb-3"
          >
            {console.log("specificEvent", specificEvent)}
            <Tab eventKey="home" title="Sport Details">
              <div className="row">
                <h3>{specificEvent?.title}</h3>

                <div className="col-lg-7 mt-4">
                  <div className="asian-description">
                   
                    <h6>Description</h6>
                    <p>
                      {subscription
                        ? specificEvent.description
                        : specificEvent.description &&
                          specificEvent.description.substring(0, 200)}
                    </p>
                    {/* <p>{readmore ? specificEvent.description : specificEvent.description}<a style={{ color: "black", cursor: "grab" }} onClick={() => readMoreText()}>{readmore ? "Read Less.." : "Read More..."}</a> */}
                    
                    {user ? !subscription || (subscription && subscription_expire) && (
                      <div className="d-flex justify-content-between align-items-baseline mt-5">
                        <h6>
                          {" "}
                          <img
                            src={require("../../images/id-card 1.png")}
                            alt=""
                          />{" "}
                          Get a SFW+ Membership
                        </h6>
                        <a className="btn" onClick={() => setShow(!show)}>
                          Subscribe Now{" "}
                        </a>
                      </div> 
                    ) : 
                    <a className="btn" onClick={() => navigate('/login')}>
                   Login{" "}
                  </a>      }
                  </div>
                  {specificEvent && specificEvent.offer_travel_option && <p><b>Offer Travel Option : </b>{specificEvent.offer_travel_option}</p>}
                  {<>{specificEvent && specificEvent.ticket_info && <p><b>Ticket Info : </b>{specificEvent.ticket_info}</p>}
                  {/* <p>Ticket Info: {specificEvent && specificEvent.ticket_info}</p> */}
                  {specificEvent.type == "subscription" ? (subscription && !subscription_expire) ? <>{specificEvent && specificEvent.upload_doc_pdf &&<p><b>Factsheet Upload Doc Pdf : </b>  <span>{specificEvent.upload_doc_pdf_title}</span>{" "}<a target="_blank" href={mediaUrl+specificEvent.upload_doc_pdf}>View</a></p>}
                 
                  {specificEvent.table_editor && <div dangerouslySetInnerHTML={{ __html: specificEvent.table_editor }} />}

                  {specificEvent && specificEvent.faq && <h6>Faq : </h6>}
                  <p>
                    {specificEvent.faq && specificEvent.faq.map((item)=>{
                      return <>
                            <div className="faq">
                                 <p><b>{"Question :"} </b>{item.question}</p>
                                 <p><b>{"Answer :"} </b>{item.answer}</p>
                            </div>
                      </>
                    })}
                  </p></> : 
                    <Button onClick={() => user ? setShow(!show) : alert("Please Login")} buttonStyle={"btn btn-primary"} label={"View More"} />  
                  : 
                  
                  <>{user && <>{specificEvent && specificEvent.upload_doc_pdf &&<p><b>Factsheet Upload Doc Pdf : </b>  <span>{specificEvent.upload_doc_pdf_title}</span>{" "}<a target="_blank" href={mediaUrl+specificEvent.upload_doc_pdf}>View</a></p>}
                 
                  {specificEvent.table_editor && <div dangerouslySetInnerHTML={{ __html: specificEvent.table_editor }} />}

                  {specificEvent && specificEvent.faq && <h6>Faq : </h6>}
                  <p>
                    {specificEvent.faq && specificEvent.faq.map((item)=>{
                      return <>
                            <div className="faq">
                                 <p><b>{"Question :"} </b>{item.question}</p>
                                 <p><b>{"Answer :"} </b>{item.answer}</p>
                            </div>
                      </>
                    })}
                  </p></>}</>

                  }
                  </>          
                  }

                </div>
                
                <SiteMapSection specificEvent={specificEvent} />
              </div>
            </Tab>
            <Tab eventKey="profile" title="All Vouchers">
            {subscription && !subscription_expire ? (
                <VoucherList id={id} />
              ) : (
                <div className="d-flex justify-content-between align-items-baseline mt-5">
                  <h6>
                    {" "}
                    <img
                      src={require("../../images/id-card 1.png")}
                      alt=""
                    />{" "}
                    Get a SFW+ Membership
                  </h6>
                  <a className="btn" onClick={() => setShow(!show)}>
                    Subscribe Now{" "}
                  </a>
                </div>
              )}
            </Tab>
          </Tabs>
        </div>
      </section>

      {/* <!-- ///////////-----Similar Events----Section---///////////// --> */}
      <SimilarEvents categoryId={specificEvent?.category_id} />
      <BoostrapModel
        show={show}
        heading={<h2>Subscription</h2>}
        size={"xl"}
        component={<Subscritpion />}
        onHide={() => setShow(!show)}
        parentClass={"subscription_plan"}
      />
      <Footer />
    </>
  );
};

export default SportDetails;
