import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  eventnearest,
  homePageApi,
  eventsearchfilter,
} from "../../services/api";
import { BsArrowRightCircle } from "react-icons/bs";
import "../../Custom.css";
import homeBanner from "../../images/home-banner.png";
import Header from "../header";
import Footer from "../footer";
import Category from "../category";
import EventNearBy from "../events/eventNearBy";
import UpComingEvent from "../events/upComingEvent";
import { MyContext } from "../../MyContext";
import CategorySection from "./categorySection";

const Home = () => {
  const userid = JSON.parse(localStorage.getItem("user"))?.id;
  const subscription = JSON.parse(localStorage.getItem("user"));


  const [latitude, setLatiude] = React.useState(false);
  const [longitude, setLongitude] = React.useState(false);
  const [nearestevent, setNearestevent] = React.useState([]);
  const [upcoming, setUpComing] = React.useState([]);
  const [text, setText] = useState({});
  const [item, setItem] = useState([]);
  const { register, handleSubmit } = useForm();
  navigator.geolocation.getCurrentPosition(function (position) {
    console.log("positon", position);
    setLatiude(position.coords.latitude);
    setLongitude(position.coords.longitude);
  });
  useEffect(() => {
    if(subscription && subscription.subscription) {
      if(subscription.subscription_expire) {
        alert("Your Subscription is Expire Renew it");
      }
    }
    getEventList();
  }, []);

  async function getEventList() {
    const body = {
      latitude: latitude ? latitude : 30.7333,
      longitude: longitude ? longitude : 76.7794,
      userid: userid,
    };
    try {
      let result = await homePageApi(body);
      console.log("result 2", result);
      setNearestevent(result.data.nearestevent);
      setUpComing(result.data.events);
    } catch (e) {
      console.log("error message", e.message);
    }
  }
  console.log("nearestEvent", nearestevent);
  const searchNearyByEvent = async (event) => {
    const body = {
      title: event.target.value,
      latitude: latitude,
      longitude: longitude,
    };
    const result = await eventsearchfilter(body);
    console.log("result", result);
    setItem(result.data.data);
  };

  return (
    <>
      <Header />
      <section
        style={{ backgroundImage: `url(${homeBanner})` }}
        id="home-banner"
      >
        <div className="container">
          <div className="home-search">
            <form>
              <div className="row">
                <div className="col-lg-5 mb-2 mt-2">
                  <div className="home-submit">
                    <input
                      id="home-search"
                      type="text"
                      name="search"
                      placeholder="Search.."
                      onChange={searchNearyByEvent}
                    />
                    {item.length > 0
                      ? item.map((item) => {
                          return (
                            <>
                              <li>
                                <Link to={`/event-details/?id=${item.id}`}>
                                  {item.title}
                                </Link>
                              </li>
                            </>
                          );
                        })
                      : ""}

                    <button className="home-search-btn" type="submit">
                      <img src={require("../../images/search.png")} alt="" />
                    </button>
                  </div>
                </div>

                <div className="col-lg-3 mb-2 mt-2">
                  <p className="filter-section">
                    <a href="#">
                      <i className="bi bi-funnel"></i>filter
                    </a>
                  </p>
                </div>

                <div className="col-lg-4 mb-2 mt-2">
                  <p className="filter-section">
                    {" "}
                    <a href="#">AZ Sports A-Z</a>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>

      {/* ///////////-----First-Carousel----Section---/////////////  */}
       <CategorySection />
      {/* ///////////-----Events Near By ----Section---/////////////  */}

      <EventNearBy event={nearestevent} />

      {/* ///////////-----Upcoming Events----Section---///////////// */}

      <UpComingEvent event={upcoming} />

      <Footer />
    </>
  );
};

export default Home;
