import React from 'react';
import { BsPlusCircle } from 'react-icons/bs';
import { Link } from "react-router-dom";
import Header from "../header";
import Footer from "../footer";
import backgroundImage from '../../images/home-banner.png';
import { createPost } from '../../services/api';

const CreatePostForm = () => {
 const userid = JSON.parse(localStorage.getItem("user")).id;
 const [description, setDescription] = React.useState("");
const test = async (e) => {
    e.preventDefault();
    const body = {
        userid: userid,
        description: description
    }
    console.log("body", body);
    const response = await createPost(body);
      if(response.data.status == "true")
      {
         alert(response.data.message);
         setDescription("");
      }
      else
      {
          alert(response.data.message)
      }
}
return (
    <>
        <Header />
        <section style={{ backgroundImage: `url(${backgroundImage})` }} id="comment-banner">
            <div class="container position-relative">
                <div class="row">
                    <div class="home-search post">
                        <form>
                            <div class="row">

                                <div class="col-lg-8 mt-3">

                                    <div class="home-submit">
                                        <input id="home-search" type="text" name="search" placeholder="Search.." />
                                        <button class="home-search-btn" type="submit"><img src={require("../../images/search.png")} alt="" />
                                        </button>
                                    </div>

                                </div>

                                <div class="col-lg-4 mb-3 mt-3">

                                    <p class="filter-section post"><Link to="/create_post"><BsPlusCircle />
                                        Post</Link></p>

                                </div>

                            </div>
                        </form>
                    </div>

                    <div class="col-6 mt-4">
                        <div class="edit-profile">
                            <i class="bi bi-people"></i>
                            <h6>Members</h6>
                            <span>2565</span>
                        </div>

                    </div>

                    <div class="col-6 mt-4">
                        <div class="edit-profile">
                            <i class="bi bi-pencil-square"></i>
                            <h6>Posts</h6>
                            <span>5446</span>
                        </div>

                    </div>

                </div>
            </div>
        </section>


        <div class="create-post">
            <div class="container">
                <div class="row">
                    <div class="col-12">

                        <div class="post-heading">
                            <form onSubmit={test}>
                                <h3>Create <span>Post</span></h3>

                                <h6>Text</h6>
                                <textarea name="" id="filed-post" onChange={(event) =>
                                    setDescription(event.target.value)
                                }
                                value={description}
                                ></textarea>
                                <p>Words count 432</p>

                                <button type="submit">Post</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Footer />
    </>
)
}

export default CreatePostForm;