import React from "react";
import OwlCarousel from "react-owl-carousel3";
import { Link } from "react-router-dom";
import axios from "axios";
import { MyContext } from "../../MyContext";
import { getallcategory } from "../../services/api";
import { mediaUrl } from "../../services/content";

const CategorySection = () => {
  const [data, setData] = React.useState([]);
  const [activeCategory, setActiveCategory] = React.useState('');
//   const sport =
//     window.location.href == "http://localhost:3000/sports" ? true : false;
//   const { text, setText } = React.useContext(MyContext);

  React.useEffect(() => {
    allCategory();
  }, [setData]);

  const allCategory = async () => {
    const result = await getallcategory();
    if (result.data.status) {
      setData(result.data.data);
    }
  };

  let responsive = {
    0: {
      items: 2,
    },
    600: {
      items: 4,
    },
    1000: {
      items: 8,
    },
  };

  return (
    <>
      <section id="first-carousel">
        <div className="container">
          <div className="row">
            <div className="col-12">
              
              <div className="home-demo">
                {/* <div className="owl-carousel owl-carouselfan owl-theme"> */}
                <OwlCarousel
                  className="owl-theme"
                  loop
                  items={8}
                  margin={10}
                  nav
                //   autoplay={true}
                  responsive={responsive}
                >
                  {data && data.length > 0
                    ? data.map((item, i) => {
                        // console.log("iteem---->", item);
                        return (
                          <div className="item">
                            <Link
                              to={`/sports/?id=${item.id}`}
                            >
                              <div className='car-img'>
                                <img src={`${mediaUrl}${item.icon}`} alt="" />
                              </div>
                              <h6>{item.title}</h6>
                            </Link>
                          </div>
                        );
                      })
                    : ""}
                </OwlCarousel>

                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CategorySection;
