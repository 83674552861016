import "bootstrap/dist/css/bootstrap.css";
import { Link } from "react-router-dom";
import footerBackgroundImage from "../../images/footer-bg.png";
const Footer = () => {
  return (
    <>
      <section
        id="footer"
        style={{ backgroundImage: `url(${footerBackgroundImage})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-6">
                  <div className="footer-logo">
                    <img src={require("../../images/footer-logo.png")} alt="" />
                    <p>
                      Sport Fan World is your ultimate companion for all things
                      sports! Our app provides fans with up-to-date information
                      about events, matches, and tournaments across the globe.
                      Whether you're a die-hard supporter or planning a
                      sports-themed trip, we've got you covered with
                      comprehensive details and travel assistance tailored to
                      your passion. Alternate: Sport Fan World: Your go-to app
                      for sports fans! Get event updates, match info, and travel
                      assistance for the ultimate fan experience
                    </p>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-3 col-md-6">
                  <div className="footer-content">
                    <h5>{"Quick Links"}</h5>

                    <ul>
                      <li>
                        {" "}
                        <Link to="/home"> {"Home"} </Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/news"> {"News"} </Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/event"> {"Events"} </Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/signup"> {"SFW+"} </Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/sports"> {"Sports"} </Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-3 col-md-6">
                  <div className="footer-content">
                    <h5>{"Other Links"}</h5>

                    <ul>
                      <li>
                        {" "}
                        <Link to="/contact"> {"Contact Us"} </Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/privacypolicy"> {"Privacy Policy"}</Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/faq"> {"FAQ"}</Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/termandcondition">
                          {" "}
                          {"Terms & Conditions"}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-3 col-md-6">
                  <div className="footer-content social-link">
                    <h5>{"Contact Us"}</h5>

                    <ul>
                      <li>{"Info@sport-world.group"}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
