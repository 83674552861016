const AboutUsSection = () => {
  return (
    <>
      <section class="travel-text mt-5">
        <div class="container">
          <h1 style={{ fontSize: "24px", fontWeight: "bold" }}>
            {"Lorem ipsum dolor sit amet."}
          </h1>
          <p class="mt-3" style={{ fontSize: "14px" }}>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque
            consequuntur tempora praesentium animi incidunt, consequatur totam
            rem molestias, et ullam quis eaque accusantium tempore ut veniam
            quasi optio, perspiciatis iusto eos velit dolores quaerat commodi
            veritatis. Praesentium dolorum molestiae officia possimus rerum
            omnis molestias reprehenderit, sapiente saepe architecto magnam
            quis! Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Deleniti eaque ea perferendis provident eum totam nulla et quae cum
            ex distinctio repudiandae accusamus, maxime laboriosam pariatur iure
            aliquid accusantium ducimus. Porro unde laboriosam deserunt
            reprehenderit recusandae ducimus id necessitatibus incidunt, sit
            possimus laborum eius earum, voluptas deleniti nemo libero in.
          </p>
        </div>
      </section>
    </>
  );
};

export default AboutUsSection;
