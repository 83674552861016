const Input = ({
  parentStyle,
  parentInlineStyle,
  label,
  labelFor,
  labelStyle,
  labelInline,
  inType,
  inputStyle,
  inputInlineStyle,
  handleChange,
  name,
  required,
  inputId,
}) => {
  return (
    <div style={parentInlineStyle} className={parentStyle}>
      <label style={labelInline} for={labelFor} class={labelStyle}>
        {label}
      </label>
      <input
        type={inType}
        style={inputInlineStyle}
        class={inputStyle}
        id={inputId}
        name={name}
        required={required}
        onChange={handleChange}
      />
    </div>
  );
};

export default Input;
