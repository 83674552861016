import moment from "moment";
import { useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel3";
import { categoryeventbyid } from "../../services/api";
import { mediaUrl } from "../../services/content";

const SimilarEvents = ({ categoryId }) => {
  const [similarEventState, setSimilarEventState] = useState([]);
  useEffect(() => {
    if (categoryId) {
      getSimilarEvents(categoryId);
    }
  }, [setSimilarEventState, categoryId]);

  const getSimilarEvents = async (categoryId) => {
    try {
      const response = await categoryeventbyid(categoryId);
      setSimilarEventState(response);
    } catch (e) {
      console.log("e", e.message);
    }
  };

  let responsive = {
    0: {
      items: 1,
    },
    450: {
      items: 2,
    },
    600: {
      items: 3,
    },
    992: {
      items: 4,
    },
  };
  console.log("similarEventState", similarEventState);
  return (
    <>
      <section id="similar-event">
        <div class="container">
          <div class="row">
            <h3>
              Similar <span>Events</span>{" "}
            </h3>

            <div class="home-demo">
              <OwlCarousel
                className="owl-theme"
                loop
                items={4}
                margin={10}
                nav={false}
                autoplay={true}
                responsive={responsive}
              >
                {similarEventState && similarEventState.length > 0
                  ? similarEventState.map((item, i) => {
                      return (
                        <>
                          <div class="item" key={i}>
                            <div class="football-event">
                              <div className="football_img" style={{ backgroundImage: `url(${mediaUrl}${item.thumbnail})` }}>
                                {/* <img
                                  class="w-100"
                                  src={`${mediaUrl}${item.thumbnail}`}
                                  alt=""
                                /> */}
                                <i class="bi bi-heart"></i>
                              </div>
                              <h6>{item.title}</h6>

                              <h5>
                                <span class="me-3">
                                  {" "}
                                  <img
                                    src={require("../../images/location_on.png")}
                                    alt=""
                                  />
                                  {item.location}
                                </span>{" "}
                                <span>
                                  <img
                                    src={require("../../images/calendar_month.png")}
                                    alt=""
                                  />
                                  {moment(item.date).format("D MMMM, YYYY")}
                                </span>
                              </h5>

                              <p>{item.description.substring(0, 100)}</p>

                              <a class="btn" href="#">
                                Read more{" "}
                                <i class="bi bi-arrow-right-circle ms-3"></i>
                              </a>
                            </div>
                          </div>
                        </>
                      );
                    })
                  : ""}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SimilarEvents;
