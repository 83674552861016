import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function BoostrapModel({ show, heading, component, onHide, size, title, parentClass, footer }) {
  return (
    <>
      <Modal show={show} onHide={onHide} size={size} className={parentClass} centered>
        <Modal.Dialog>

          {title
            ?
            <Modal.Header closeButton>
              <Modal.Title className='no-underline'>{heading}</Modal.Title>
            </Modal.Header>
            : <>
              {heading}
              <button type="button" class="btn-close" onClick={onHide}></button>
            </>}

          <Modal.Body>{component}</Modal.Body>
          {footer && <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>
              Close
            </Button>
            {/* <Button variant="primary" onClick={onHide}>
            Save Changes
          </Button> */}
          </Modal.Footer>}
        </Modal.Dialog>
      </Modal >
    </>
  );
}

export default BoostrapModel;