import axios from "axios";
import { ApiUrl, liveApiUrl } from "./content";
export const eventnearest = async (data) => {
  const response = await axios.post(`${ApiUrl}nearestevent`, data);
  return response.data.message;
};

export const upcomingevent = async () => {
  const response = await axios.get(`${ApiUrl}allevents`);
  return response.data.data;
};

export const eventdetails = async (id) => {
  const body = {
    eventid: id,
  };
  const response = await axios.post(`${ApiUrl}specificevent`, body);
  return response.data.data;
};

export const categoryeventbyid = async (id) => {
  const body = {
    category_id: id,
  };
  const response = await axios.post(`${ApiUrl}geteventbycategoryid`, body);
  return response.data.data;
};

export const allnews = async () => {
  const response = await axios.get(`${ApiUrl}allnews`);
  return response.data.message;
};

export const specificnews = async (id) => {
  const body = {
    id: id,
  };
  const response = await axios.post(`${ApiUrl}getnewsbyid`, body);
  return response;
};

export const getallcategory = async () => {
  const response = await axios.get(`${ApiUrl}getallcategory`);
  return response;
};

export const getsubcategorybycatid = async (id) => {
  const body = {
    category_id: id,
  };

  const response = axios.post(`${ApiUrl}getsubcategorybycatid`, body);
  return response;
};

export const eventfilter = async (body) => {
  const response = await axios.post(`${ApiUrl}eventfilter`, body);
  return response;
};

export const eventsearchfilter = async (body) => {
  const response = await axios.post(`${ApiUrl}eventsearch`, body);
  return response;
};

export const homePageApi = async (body) => {
  const response = await axios.post(`${ApiUrl}home`, body);
  return response;
};

export const createPost = async (body) => {
  const response = await axios.post(`${ApiUrl}createpost`, body);
  return response;
};

export const categoryWithEventCount = async () => {
  const response = await axios.get(`${ApiUrl}categorywithevent`);
  return response;
};

export const getPostForum = () => {
  const response = axios.get(`${ApiUrl}getpost`);
  return response;
};

export const getAllVoucherApi = async (body) => {
  const response = await axios.post(`${ApiUrl}getAllVoucher`, body);
  return response;
};

export const getSingleVoucherApi = async (body) => {
  const response = await axios.post(`${ApiUrl}singleVoucherDetails`, body);
  return response;
};

export const postlikeApi = async (body) => {
  const response = await axios.post(`${ApiUrl}postlike`, body);
  return response;
};

export const postCommentApi = async (body) => {
  const response = await axios.post(`${ApiUrl}postComment`, body);
  return response;
};

export const allSubscription = async () => {
  const response = await axios.get(`${ApiUrl}allSubscription`);
  return response;
};

export const buySubscriptionApi = async (data) => {
  try {
    const response = await axios.post(`${ApiUrl}buy_subscription`, data);
    return response;
  } catch (e) {
    console.log("error", e);
  }
};

export const savedVoucherApi = async (data) => {
  try {
    const response = await axios.post(`${ApiUrl}addSavedVoucher`, data);
    return response;
  } catch (e) {
    console.log("error", e);
  }
};

export const getProfileDataApi = async (data) => {
  try {
    const response = await axios.post(`${ApiUrl}getUserById`, data);
    return response;
  } catch (e) {
    console.log("error", e);
  }
};

export const profileUpdateApi = async (data) => {
  try {
    const response = await axios.post(`${ApiUrl}profileUpdate`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (e) {
    console.log("error", e);
  }
};

export const dashboardVoucherApi = async (body) => {
  try {
    const response = await axios.post(`${ApiUrl}dashboardVouchers`, body);
    return response;
  } catch (e) {
    console.log("error", e);
  }
};

export const addSavedEventApi = async (body) => {
  try {
    const response = await axios.post(`${ApiUrl}addSavedevents`, body);
    return response;
  } catch (e) {
    console.log("error", e);
  }
};

export const getSavedEventApi = async (body) => {
  try {
    const response = await axios.post(`${ApiUrl}getSavedEvent`, body);
    return response;
  } catch (e) {
    console.log("error", e);
  }
};

// sports

export const getAllSportsApi = async () => {
  try {
   const response = await axios.get(`${ApiUrl}allSports`);
     return response;
  } catch (e) {
    throw e;
  }
}
