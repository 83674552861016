import React, { useEffect } from "react";
import moment from "moment";
import Swal from 'sweetalert2';
import { allSubscription, buySubscriptionApi } from "../../services/api";
import { useNavigate } from "react-router-dom";
import LoaderGif from "../../loader";

const Subscritpion = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = React.useState(false);
    const userid = JSON.parse(localStorage.getItem('user'))?.id;
    const subscription = JSON.parse(localStorage.getItem("user"));
    const [data, setData] = React.useState([]);
    React.useEffect(() => {
        allSubscripitonFun();
    }, [])
    const allSubscripitonFun = async () => {
        setIsLoading(true)
        try {
            const response = await allSubscription();
            if (response.data.status) {
                setIsLoading(false)
                setData(response.data.data);
            }
        }
        catch (e) {
            setIsLoading(false)
            console.log("error", e);
        }
    }

    const buySubscripiton = async (e) => {
        setIsLoading(true)
        e.preventDefault();
        if (!JSON.parse(localStorage.getItem('user'))) {
            setIsLoading(false)
            navigate('/signup')
        } 
        else {
            const duration = e.target.duration.value;
            const months = duration.split(' ')[0];
            const data = {
                subscription_id: e.target.subscription_id.value,
                user_id: userid,
                start_date: moment().format('YYYY-MM-DD'),
                end_date: moment().add(months, 'months').format('YYYY-MM-DD')
            }
            try {
            const response = await buySubscriptionApi(data);
            if(response.data.status) {
                setIsLoading(false)
                Swal.fire('Successfully Subscribe Now');
                localStorage.setItem('user', JSON.stringify(response.data.data))
                navigate('/home');
            }
            }
            catch (e) {
                setIsLoading(false)
                console.log("error", e);
            }
            console.log("data", data);
        }
    }
  
    console.log("subscripgtion", data);
    return (<>{isLoading ? <LoaderGif /> : <div class="row">
        {data && data.length > 0 && data.map((item) => {
            return (<div class="col-md-6">
                <div class="set text-center">
                    <h3>{item.subscription_type.toUpperCase()}</h3>
                    <div class="img">
                        <img src="../images/Plan1.png" />
                    </div>
                    <h1>{item.Price}</h1>

                    <p>Duration: {item.Duration}</p>
                    <ul>
                        <li>Lorem Ipsum is simply dummy text</li>
                        <li>Lorem Ipsum is simply dummy text</li>
                        <li>Lorem Ipsum is simply dummy text</li>
                        <li>Lorem Ipsum is simply dummy text</li>
                        <li>Lorem Ipsum is simply dummy text</li>
                    </ul>
                    <form onSubmit={buySubscripiton}>
                        <input type="hidden" name="subscription_id" value={item.id} />
                        <input type="hidden" name="duration" value={item.Duration} />
                        <button type="submit" class="buy_btn">
                            {subscription && subscription.subscription ? !subscription.subscription_expire ? 'Renew it' : "Buy Now" : "Buy Now"}</button>
                    </form>

                </div>
            </div>)
        })}
    </div>}</>)
}
export default Subscritpion;