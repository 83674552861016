import { useEffect, useState, useRef } from "react";
import {
  StandaloneSearchBox,
  LoadScript,
  useLoadScript,
} from "@react-google-maps/api";
import { BsArrowRightCircle } from "react-icons/bs";
import { useForm } from "react-hook-form";
import "bootstrap/dist/css/bootstrap.css";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { MyContext } from "../../MyContext";
import Header from "../header";
import Category from "../category";
import Footer from "../footer";
import {
  upcomingevent,
  categoryeventbyid,
  getallcategory,
  eventfilter,
  getAllSportsApi,
} from "../../services/api";
import { mediaUrl } from "../../services/content";

const Sport = () => {
  // window.location.reload();
  // const urlParams = new URL(window.location.href).searchParams;
  // const navigate = useNavigate();
  const [text, setText] = useState({});
  const [event, setEvent] = useState([]);
  const [sportsData, setSportsData] = useState([]);
  const [getCategoryData, setGetCategoryData] = useState([]);
  const [searchBox, setSearchBox] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  // const lib = ["places"];
  // const category_id = text[0]?.categoryId ? text[0].categoryId : null;

  useEffect(() => {
    getAllSports();
    allEventSport();
    getAllCategory();
    // getEventByCategoryId(id);
  }, [setEvent, setSearchBox, setGetCategoryData, setGetCategoryData]);

  const getEventByCategoryId = async (id) => {
    const result = await categoryeventbyid(id);
    setEvent(result);
  };

  const allEventSport = async () => {
    const result = await upcomingevent();
    setEvent(result);
  };

  const getAllCategory = async () => {
    const response = await getallcategory();
    setGetCategoryData(response.data.data);
  };
  const inputRef = useRef();

  const handlePlaceChanged = () => {
    const [place] = searchBox.getPlaces();
    if (place) {
      console.log(place.formatted_address);
      console.log(place.geometry.location.lat());
      console.log(place.geometry.location.lng());
    }
  };

   const getAllSports = () => {
    getAllSportsApi().then((response)=>{
      if(response.data.status) {
        setSportsData(response.data.data);
      } else {
        alert(response.data.message);
      }
    }).catch((e) => console.log("error", e))
  }

  const onSubmit = async (body) => {
    body.category_id = body.category_id ? body.category_id.toString() : "";

    eventfilter(body).then((response)=>{
      if(response.data.status) {
        setSportsData(response.data.data);
      } else {
        alert(response.data.message)
      }
    }).catch((e) => {
      console.log("error", e);
    })
    // if (result.data.status == "true") {
    //   setEvent(result.data.data);
    // }
  };
  // console.log("event", event);
  return (
    <>
      <Header />
      <MyContext.Provider value={{ text, setText }}>
        <Category />
      </MyContext.Provider>
      <section id="filter">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-4">
              <div className="category-filter">
                <h4>
                  <i className="bi bi-funnel-fill"></i>Filter{" "}
                  <span className="float-end" onClick={() => reset()}>
                    Clear
                  </span>{" "}
                </h4>

                <form className="date-filter" onSubmit={handleSubmit(onSubmit)}>
                  <h5>When</h5>

                  <div className="mb-2">
                    <label for="exampleInputdate" className="form-label">
                      From
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="exampleInputdate"
                      {...register("from")}
                    />
                  </div>

                  <div className="mb-2">
                    <label for="exampleInputdate2" className="form-label">
                      To
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="exampleInputdate2"
                      {...register("to")}
                    />
                  </div>

                  <h6>Where</h6>
                  <LoadScript
                    googleMapsApiKey={`AIzaSyDA-fY7vuZTLt0xvKABD82EOfHIkeCHFGY`}
                    libraries={["places"]}
                  >
                    <StandaloneSearchBox
                      onLoad={(ref) => setSearchBox(ref)}
                      onPlacesChanged={() => handlePlaceChanged}
                    >
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Enter Location"
                        {...register("location")}
                      />
                    </StandaloneSearchBox>
                  </LoadScript>
                  <h6>{"Categories"}</h6>

                  {getCategoryData &&
                    getCategoryData.length > 0 &&
                    getCategoryData?.map((item) => {
                      return (
                        <div className="form-check mb-2">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={item?.id}
                            id="flexCheckDefault"
                            {...register("category_id")}
                          />
                          <label
                            className="form-check-label"
                            for="flexCheckDefault"
                          >
                            {item?.title}
                          </label>
                        </div>
                      );
                    })}

                  <button type="submit">Apply</button>
                </form>
              </div>
            </div>

            <div className="col-xl-9 col-lg-8">
              <div className="row">
                {sportsData.length > 0
                  ? sportsData?.map((item) => {
                      return (
                        <div className="col-xl-4 col-lg-6 col-sm-6 mb-4">
                          <div className="football-event">
                            <div className="img" style={{ backgroundImage: `url(${mediaUrl}${item.thumbnail})` }}></div>
                            <h6>{item.title}</h6>

                            <h5>
                              <span className="me-3">
                                {" "}
                                <img
                                  src={require("../../images/location_on.png")}
                                  alt=""
                                />
                                {item.location.includes(",")
                                  ? `${item.location.split(",")[0]}, ${
                                      item.location.split(",")[1]
                                    }`
                                  : item.location}
                              </span>
                              <span>
                                <img
                                  src={require("../../images/calendar_month.png")}
                                  alt=""
                                />
                                {moment(item.date).format("D MMMM, YYYY")}
                              </span>
                            </h5>

                            <p>{item.description.substring(0, 200)}</p>

                            <Link
                              className="btn"
                              to={`/sport-details/?id=${item.id}`}
                            >
                              {"Read more"}
                              <BsArrowRightCircle classNameName="ms-2" />
                            </Link>
                          </div>
                        </div>
                      );
                    })
                  : "No Sports"}

                {/* <div className="page-next">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination">
                      <li className="page-item">
                        <a className="page-link" href="#">
                          Previous
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          1
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          2
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          3
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          Next
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Sport;
