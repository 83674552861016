import { Link } from "react-router-dom";
import { AiFillHome } from 'react-icons/ai';
import Header from "../header";
import Footer from "../footer";
import policyBanner from '../../images/profile-bg.png';

const privacyPolicy = () => {
   return (
       <>
       <Header />
       {/* <!-- ///////////-----blog-banner----Section---///////////// --> */}

<section style={{backgroundImage:`url(${policyBanner})`}} id="blog-banner">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h2>Privacy Policy</h2>
                <ul class="bcrumbs">
                    <li><Link href="index.html"><AiFillHome style={{color: "white"}}/></Link></li>
                    <li>Privacy Policy</li>
                </ul>
            </div>
        </div>
    </div>
</section>

{/* <!-- ///////////-----Privacy Policy----Section---///////////// --> */}


<section id="privacy-policy">
    <div class="container">
        <div class="row">
            <div class="col-12">

                <h3>Introduction</h3>

                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit, dolorum ullam! Corrupti
                    quisquam consequuntur aliquam optio ut rem. Vitae, eum assumenda. Eos blanditiis, quod minima
                    soluta porro beatae distinctio quos.</p>

                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit, dolorum ullam! Corrupti
                    quisquam consequuntur aliquam optio ut rem. Vitae, eum assumenda. Eos blanditiis, quod minima
                    soluta porro beatae distinctio quos.</p>

                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit, dolorum ullam! Corrupti
                    quisquam consequuntur aliquam optio ut rem. Vitae, eum assumenda. Eos blanditiis, quod minima
                    soluta porro beatae distinctio quos.</p>

                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit, dolorum ullam! Corrupti
                    quisquam consequuntur aliquam optio ut rem. Vitae, eum assumenda. Eos blanditiis, quod minima
                    soluta porro beatae distinctio quos.</p>

                <h3>Managing Your information</h3>

                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit, dolorum ullam! Corrupti
                    quisquam consequuntur aliquam optio ut rem. Vitae, eum assumenda. Eos blanditiis, quod minima
                    soluta porro beatae distinctio quos. amet, consectetur adipisicing elit. Reprehenderit, dolorum
                    ullam! Corrupti
                    quisquam consequuntur aliquam optio ut rem. Vitae, eum assumenda.</p>

                <p>You must not:</p>

                <ul>
                    <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis, omnis fuga. </li>
                    <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis, omnis fuga. </li>
                    <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis, omnis fuga. </li>
                    <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis, omnis fuga. </li>
                    <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis, omnis fuga. </li>
                </ul>

                <h3>Scope of this Privacy Policy</h3>

                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit, dolorum ullam! Corrupti
                    quisquam consequuntur aliquam optio ut rem. Vitae, eum assumenda. Eos blanditiis, quod minima
                    soluta porro beatae distinctio quos.</p>

                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit, dolorum ullam! Corrupti
                    quisquam consequuntur aliquam optio ut rem. Vitae, eum assumenda. Eos blanditiis, quod minima
                    soluta porro beatae distinctio quos.</p>

                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit, dolorum ullam! Corrupti
                    quisquam consequuntur aliquam optio ut rem. Vitae, eum assumenda. Eos blanditiis, quod minima
                    soluta porro beatae distinctio quos.</p>

                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit, dolorum ullam! Corrupti
                    quisquam consequuntur aliquam optio ut rem. Vitae, eum assumenda. Eos blanditiis, quod minima
                    soluta porro beatae distinctio quos.</p>

                <ul>
                    <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis, omnis fuga. </li>
                    <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis, omnis fuga. </li>
                    <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis, omnis fuga. </li>
                    <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis, omnis fuga. </li>
                    <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis, omnis fuga. </li>
                </ul>


            </div>
        </div>
    </div>
</section>

       <Footer />
       </>
   )
}

export default privacyPolicy;