import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import 'bootstrap/dist/css/bootstrap.css';
import Header from '../header';
import Footer from '../footer';
import { AiFillHome } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import faqBackground from '../../images/profile-bg.png';

const faq = () => {
    return (
        <>
            <Header />
            <section style={{ backgroundImage: `url(${faqBackground})` }} id="blog-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2>FAQ</h2>
                            <ul className="bcrumbs">
                                <li><Link to="home"><AiFillHome style={{ color: "white" }} /></Link></li>
                                <li>FAQ</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section id="faq">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-8 col-md-7">

                            <div className="faq-tab">

                                <div className="faq-heading">
                                    <h3>Frequently Asked <span style={{ color: "#871614" }}>Questions</span> </h3>
                                    <span className="tiny-border"></span>
                                </div>

                                <Accordion>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Lorem ipsum dolor sit amet consectetur.?</Accordion.Header>
                                        <Accordion.Body>
                                        Lorem ipsum dolor sit amet consectetur. Magna amet hac ultricies enim
                                                ridiculus commodo dictum a neque. Eu fermentum vitae suscipit cursus sapien
                                                sed nunc sed. Egestas urna nec cras pellentesque sollicitudin viverra. Quis
                                                ipsum pulvinar at vitae tristique commodo lectus convallis. Hac nisl porta
                                                quam et condimentum amet mauris. Urna maecenas dui id interdum. Dictum sit
                                                et odio libero. Elementum pellentesque morbi mus sed est porttitor. Magna
                                                elementum tellus quis sit tristique massa arcu enim lorem.
                                                Turpis cras phasellus feugiat posuere neque pellentesque ut risus. Non nunc
                                                morbi placerat dictum sit mi convallis vitae. Eget ultrices felis hac quis.
                                                Hendrerit sit lobortis turpis dictum. Odio elementum nibh in eu nunc. Risus
                                                felis viverra volutpat arcu amet mauris fringilla laoreet.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>Lorem ipsum dolor sit amet consectetur.</Accordion.Header>
                                        <Accordion.Body>
                                        <strong>This is the second item's accordion body.</strong> It is hidden by
                                                default,
                                                until the collapse plugin adds the appropriate classNamees that we use to style each
                                                element. These classNamees control the overall appearance, as well as the showing
                                                and
                                                hiding via CSS transitions. You can modify any of this with custom CSS or
                                                overriding
                                                our default variables. It's also worth noting that just about any HTML can go
                                                within
                                                the <code>.accordion-body</code>, though the transition does limit overflow.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>Lorem ipsum dolor sit amet consectetur.</Accordion.Header>
                                        <Accordion.Body>
                                        <strong>This is the third item's accordion body.</strong> It is hidden by
                                                default,
                                                until the collapse plugin adds the appropriate classNamees that we use to style each
                                                element. These classNamees control the overall appearance, as well as the showing
                                                and
                                                hiding via CSS transitions. You can modify any of this with custom CSS or
                                                overriding
                                                our default variables. It's also worth noting that just about any HTML can go
                                                within
                                                the <code>.accordion-body</code>, though the transition does limit overflow.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>Lorem ipsum dolor sit amet consectetur.</Accordion.Header>
                                        <Accordion.Body>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                            minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                            aliquip ex ea commodo consequat. Duis aute irure dolor in
                                            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                            culpa qui officia deserunt mollit anim id est laborum.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>Lorem ipsum dolor sit amet</Accordion.Header>
                                        <Accordion.Body>
                                        <strong>This is the third item's accordion body.</strong> It is hidden by
                                                default,
                                                until the collapse plugin adds the appropriate classNamees that we use to style each
                                                element. These classNamees control the overall appearance, as well as the showing
                                                and
                                                hiding via CSS transitions. You can modify any of this with custom CSS or
                                                overriding
                                                our default variables. It's also worth noting that just about any HTML can go
                                                within
                                                the <code>.accordion-body</code>, though the transition does limit overflow.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-5 mt-4">
                            <div className="faq-img">
                                <img className="w-100" src={require("../../images/footbal-faq.jpg")} alt="" />
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default faq;