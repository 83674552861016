import { useState, useEffect } from "react";
import axios from "axios";
import { upcomingevent } from "../../services/api";
import { BsArrowRightCircle } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { mediaUrl } from "../../services/content";

const UpComingEvent = ({ event }) => {
  const navigate = useNavigate();
  return (
    <>
      <section id="upcoming-events">
        <div className="container">
          <div className="row">
            <h3>
              Upcoming <span>Events</span>
            </h3>
            {event &&
              event.length > 0 &&
              event?.map((item) => {
                return (
                  <div className="col-lg-3 col-sm-6 mb-4">
                    <div className="football-event">
                    <div onClick={() => navigate(`/event-details/?id=${item.id}`)} className="img" style={{ backgroundImage: `url(${mediaUrl}${item.thumbnail}` }}> </div>
                      {/* <img
                        className="w-100"
                        src={`${mediaUrl}${item.thumbnail}`}
                        alt=""
                      /> */}
                      <h6>{item.title}</h6>
                      <h5>
                        <span className="me-3">
                          {" "}
                          <img
                            src={require("../../images/location_on.png")}
                            alt=""
                          />
                          {item.location.includes(",")
                            ? `${item.location.split(",")[0]}, ${
                                item.location.split(",")[1]
                              }`
                            : item.location}
                        </span>{" "}
                        <span>
                          <img
                            src={require("../../images/calendar_month.png")}
                            alt=""
                          />
                          {moment(item.date).format("D MMMM, YYYY")}
                        </span>
                      </h5>

                      <p>{item.description.substring(0, 50)}</p>

                      <Link class="btn" to={`/event-details/?id=${item.id}`}>
                        Read more <BsArrowRightCircle className="ms-2" />
                      </Link>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
    </>
  );
};

export default UpComingEvent;
