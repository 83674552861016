import { Link } from "react-router-dom";
const ForgotPassword = () => {
    return (
        <>
            <form>
                <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">Email address</label>
                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />

                </div>

                <button type="submit" style={{
                    background: "#871614",
                    borderRadius: "10px",
                    width: "100%",
                    height: "45px",
                    border: "none",
                    color: "#fff"
                }}> <Link to="/reset_password" style={{textDecoration: "none", color: "white"}}>Continue</Link> </button>

            </form>

        </>
    )
}

export default ForgotPassword;