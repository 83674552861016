import moment from "moment";

const SiteMapSection = ({specificEvent}) => {
    return (<>
    <div className="col-lg-5 mt-4">
                  <div className="asian-date">
                    <h6>Date & Time</h6>
                    <p>
                      {" "}
                      <img
                        src={require("../../images/calendar_month.png")}
                        alt=""
                      />{" "}
                      {moment(specificEvent?.date).format("D MMMM, YYYY")} at{" "}
                      {specificEvent?.start_time?.split[":"] <= 12
                        ? `${specificEvent?.start_time} AM`
                        : `${specificEvent?.start_time} PM`}
                    </p>

                    <h6>Event Location</h6>
                    <p>
                      {" "}
                      <img
                        src={require("../../images/location_on.png")}
                        alt=""
                      />
                      {specificEvent?.location}
                    </p>

                    <p>Rafferty Stadium, Fairfield, CT 06824, United States</p>

                    <iframe
                      src={`https://maps.google.com/maps?q=${specificEvent?.lat},${specificEvent?.long}&hl=es;z=14&amp;output=embed`}
                      style={{ border: 0 }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
    </>)
}

export default SiteMapSection;