import { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Link, useNavigate } from "react-router-dom";
import { MdLocationOn } from "react-icons/md";
import Dropdown from "react-bootstrap/Dropdown";
import "bootstrap/js/src/collapse.js";

const Header = () => {
  const userData = JSON.parse(localStorage.getItem("user"));
  const logoutFun = () => {
    localStorage.removeItem("user");
    window.location.href = "/login";
  };

  let url = window.location.href;
   console.log("url===>", url.split('/')[3] == "sports" ? true : false)
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container">
          <a className="navbar-brand">
            {" "}
            <img
              className="w-100"
              src={require("../../images/logo-2.png")}
              alt=""
            />{" "}
          </a>

          <div className="collapse navbar-collapse" id="navbarNav">
            <div className="w-100">
              <ul className="navbar-nav float-end">
                <li className="nav-item">
                  <Link
                    className={`nav-link ${(url.split('/')[3] == "home" || !url.split('/')[3]) && 'active'}`}
                    aria-current="page"
                    to="/home"
                  >
                    {"Home"}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={`nav-link ${url.split('/')[3] == "news" && 'active'}`} to="/news">
                    {"News"}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={`nav-link ${url.split('/')[3] == "event" && 'active'}`} to="/event">
                    {"Events"}
                  </Link>
                </li>

                {/* <li className="nav-item">
                                    <Link className="nav-link" to="/forum">Forum</Link>
                                </li> */}

                <li className="nav-item">
                  <Link className={`nav-link ${url.split('/')[3] == "sports" && 'active'}`} to="/sports">
                    {"Sports"}
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="responsive-login profile home">
            <div className="top-btn profile home">
              <ul className="d-flex align-items-center">
                {/* <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDarkDropdownMenuLink" role="button"
                                data-bs-toggle="dropdown" aria-expanded="false" onClick={() => setShow(!show)}>
                                <MdLocationOn />Fairfield, US
                            </a>
                            {show ? <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDarkDropdownMenuLink">
                                <li><a className="dropdown-item" href="#">Action</a></li>
                                <li><a className="dropdown-item" href="#">Another action</a></li>
                                <li><a className="dropdown-item" href="#">Something else here</a></li>
                            </ul> : ''}
                        </li> */}
                <li className="nav-item">
                  <Dropdown className="d-inline mx-2">
                    <Dropdown.Toggle
                      id="dropdown-autoclose-true"
                      style={{
                        background: "white",
                        color: "#871614",
                        fontWeight: 600,
                        fontSize: "14px",
                        border: "1px solid white",
                        padding: "1px",
                      }}
                    >
                      <MdLocationOn style={{ fontSize: "17px" }} />
                      {userData ? userData.location : "Fairfield, US"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="#">{"Action"}</Dropdown.Item>
                      <Dropdown.Item href="#">{"Another action"}</Dropdown.Item>
                      <Dropdown.Item href="#">
                        {"Something else here"}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>

                <li className="nav-item dropdown sfw">
                  <Dropdown className="d-inline mx-2">
                    <Dropdown.Toggle
                      className="btn btn-secondary"
                      id="dropdownMenuLink"
                    >
                      {"SFW+"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="/">
                        {"Become a SFW+ member"}
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <Link to={"/forum"}>{"Voucher"}</Link>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <Link to={"/forum"}>{"Tickets"}</Link>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <Link to={"/travelBooking"}>{"Travel Booking"}</Link>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <Link to={"/forum"}>{"Forum"}</Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>

                <li className="nav-item dropdown">
                  {userData ? (
                    <Link onClick={logoutFun} className="btn btn-dark">
                      <label>
                        <i className="fa-solid fa-arrow-right-to-bracket"></i>
                      </label>
                      <p>{"Logout"}</p>{" "}
                    </Link>
                  ) : (
                    <Link to="/login" className="btn btn-dark">
                      <label>
                        <i className="fa-solid fa-arrow-right-to-bracket"></i>
                      </label>
                      <p>{"Login"}</p>{" "}
                    </Link>
                  )}
                </li>
              </ul>
            </div>

            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
