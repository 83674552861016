import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import axios from 'axios';
import Header from "../header";
import Footer from "../footer";
import BoostrapModel from '../model/model'
import ForgotPassword from "../forgot-password";
import { ApiUrl } from "../../services/content";

const loginSchema = yup.object().shape({
    password: yup.string().required(),
    email: yup.string().required().email(),
  }).required();

const Login = () => {
    // const [user, setUser] = useState(false);
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [userExixt, setUserExixt] = React.useState(false);
    
    // useEffect(()=>{
    //     const authenticated = Boolean(localStorage.getItem('user'));
    //     setUser(authenticated);
    // })
    // if(user) {
    //     console.log("user", user);
    //     navigate('/home');
    // }
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(loginSchema),
     });
    console.log("error", errors);
    const userss = JSON.parse(localStorage.getItem('user'));
    if(userss) {
     navigate('/home')
    }
    else {
        console.log("user3", "Not login")
    }
     const onSubmit = (data) => {
         axios.post(`${ApiUrl}user_login`, data).then((response)=>{
           if(response.data.status == "true") 
           {
             alert(response.data.message);
             localStorage.setItem('user', JSON.stringify(response.data.user));
            // navigate('/home')
            setUserExixt(true)
            }
           else
           {
             alert(response.data.message);
           }
         }).catch((error)=>{
             console.log("error", error);
         })
     }

    return (
        <>
        <Header />
          <section id="login">
        <div class="container">
            <div class="row g-0 shadow-border">

                <div class="col-lg-6 col-md-12">
                    <img class="w-100 h-100" src={require("../../images/login-img.png")} alt="" />
                </div>

                <div class="col-lg-6 col-md-12 mt-4">
                    <div class="login-heading">
                        <h3>Welcome <span style={{color: "#871614"}}>Back!</span></h3>
                        <p>Login with your credentials</p>

                        <ul>
                            <li>
                                <a href="#"> <img src={require("../../images/facebook.png")} alt="" /></a>
                            </li>

                            <li>
                                <a href="#"> <img src={require("../../images/google.png")} alt="" /></a>
                            </li>

                        </ul>
                        <h6> <span>or</span></h6>

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Email address</label>
                                <input type="email" class="form-control" {...register('email')} id="exampleInputEmail1"
                                    aria-describedby="emailHelp" />
                                {errors.email && <p style={{color: "red"}}>{errors.emailForgotPassword.message}</p>}
                            </div>

                            <div class="mb-3">
                                <label for="exampleInputPassword1" class="form-label">Password</label> <a
                                    class="float-end" data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                                    onClick={() => setShow(!show)}>Forgot Password?</a>
                                <input type="password" class="form-control" id="exampleInputPassword1"  {...register('password')} />
                                {errors.password && <p style={{color: "red"}}>{errors.password.message}</p>}
                            </div>


                            <div class="mb-3 form-check">
                                <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                                <label class="form-check-label" for="exampleCheck1">Keep me Loggedin</label>
                            </div>

                            <button type="submit" class="btn btn-primary">Login</button>
                            <div id="emailHelp" class="form-text">Don't have an account? <Link to="/signup">Create a
                                    account</Link> </div>
                        </form>

                    </div>
                </div>


            </div>
        </div>
    </section>
    <BoostrapModel heading={"Forgot Password"} show={show} component={<ForgotPassword />} onHide={() => setShow(!show)} />
    <Footer />
        </>
    )
}

export default Login;