import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const boostrapModel = (props) => {
    return (
        <>
            <Modal
                {...props}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {props?.heading}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props?.component}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default boostrapModel;