import React from "react";
import { BsChatDots, BsSend, BsPlusCircle, BsJoystick } from "react-icons/bs";
import { AiOutlineHeart } from "react-icons/ai";
import { useForm } from "react-hook-form";
import "bootstrap/dist/css/bootstrap.css";
import moment from "moment";
import $ from "jquery";
import Header from "../header";
import Footer from "../footer";
import forumBackgroundImage from "../../images/home-banner.png";
import { Link } from "react-router-dom";
import { getPostForum, postlikeApi, postCommentApi } from "../../services/api";
import LoaderGif from "../../loader";
import { mediaUrl } from "../../services/content";

const Forum = () => {
  const [post, setPost] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const { register, handleSubmit } = useForm();
  const userid = JSON.parse(localStorage.getItem("user"))?.id;
  React.useEffect(() => {
    getPost();
  }, [setPost]);
  const getPost = async () => {
    const result = await getPostForum();
    console.log("dtaa", result);
    setPost(result.data.post);
  };
  const createLikeComment = async (e) => {
    e.preventDefault();
    let data;
    if (e.target.isparent_id && e.target.isparent_id.value) {
      data = {
        isparent_id: e.target.isparent_id.value,
        user_id: userid,
        comment: e.target.comment.value,
        post_id: e.target.post_id.value,
      };
    } else {
      data = {
        post_id: e.target.postid.value,
        user_id: userid,
        comment: e.target.comment.value,
      };
    }
    try {
      const result = await postCommentApi(data);
      console.log("result", result);
      if (result.data.status) {
        alert("SuccessFully");
        getPost();
      }
    } catch (e) {
      console.log("error", e);
    }
    // console.log("body", body);
  };

  const handleLike = async (postId, commentId) => {
    let body;
    if (!commentId) {
      body = {
        post_id: postId,
        user_id: userid,
      };
    } else {
      body = {
        post_id: postId,
        user_id: userid,
        commentId: commentId,
      };
    }
    try {
      const response = await postlikeApi(body);
      if (response.data.status) {
        alert("successfully");
        getPost();
      }
    } catch (e) {
      console.log("error", e);
    }
  };
  return (
    <>
      <Header />
      <section
        style={{ backgroundImage: `url(${forumBackgroundImage})` }}
        id="comment-banner"
      >
        <div className="container position-relative">
          <div className="row">
            <div className="home-search post">
              <form>
                <div className="row">
                  <div className="col-lg-8 mt-3">
                    <div className="home-submit">
                      <input
                        id="home-search"
                        type="text"
                        name="search"
                        placeholder="Search.."
                      />
                      <button className="home-search-btn" type="submit">
                        <img src={require("../../images/search.png")} alt="" />
                      </button>
                    </div>
                  </div>

                  <div className="col-lg-4 mb-3 mt-3">
                    <p className="filter-section post">
                      <Link to="/create_post">
                        <BsPlusCircle />
                        Create Post
                      </Link>
                    </p>
                  </div>
                </div>
              </form>
            </div>

            <div className="col-6 mt-4">
              <div className="edit-profile">
                <i className="bi bi-people"></i>
                <h6>Members</h6>
                <span>2565</span>
              </div>
            </div>

            <div className="col-6 mt-4">
              <div className="edit-profile">
                <i className="bi bi-pencil-square"></i>
                <h6>Posts</h6>
                <span>5446</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="comment-box">
        <div className="container">
          <div className="border-outline-box">
            <div className="row">
              {post && post.length > 0
                ? post.map((item) => {
                    return (
                      <>
                        <div className="first-comment">
                          <div className="comment-img">
                            <img
                              src={
                                item.postUserImage
                                  ? mediaUrl + item.postUserImage
                                  : require("../../images/women.png")
                              }
                              alt=""
                            />
                            <div className="comment-name">
                              <h6>{item.postUserFullName}</h6>
                              {/* <p>2 March at 2:00 pm</p> */}
                              <p>
                                {moment(item.postCreated_at).format("D MMMM")}{" "}
                                at{" "}
                                {moment(item.postCreated_at).format("H:MM a")}
                              </p>
                            </div>
                          </div>

                          <p className="comment-para">{item.postDescription}</p>

                          <ul className="share-section">
                            <li onClick={() => handleLike(item.postId)}>
                              <span>
                                <AiOutlineHeart className="me-2" />
                                {item.postLikeCount} Like
                              </span>
                            </li>
                            <li
                              onClick={() => {
                                setShow(item.postId);
                                $(".mini-comment").css("display", "none");
                                $(".comment-reply").css("display", "none");
                                $(`#modelOpenClose${item.postId}`).css(
                                  "display",
                                  "block"
                                );
                              }}
                            >
                              <BsChatDots className="me-2" />
                              {item.postCommentCount} Comment
                            </li>
                            <li>
                              <BsSend className="me-2" />
                              Share
                            </li>
                          </ul>
                        </div>
                        <div
                          className="mini-comment"
                          style={{ display: "none" }}
                          id={`modelOpenClose${item.postId}`}
                        >
                          {item.comment.length > 0
                            ? item.comment.map((comment) => {
                                return (
                                  <div className="mini-group">
                                    <div className="comment-img mini">
                                      <img
                                        src={
                                          comment.userImage
                                            ? mediaUrl + comment.userImage
                                            : require("../../images/women.png")
                                        }
                                        alt=""
                                      />
                                      <div className="comment-name">
                                        <h6>{comment.userFullName}</h6>
                                        <p>
                                          {moment(comment.created_at).format(
                                            "D MMMM"
                                          )}{" "}
                                          at{" "}
                                          {moment(comment.created_at).format(
                                            "H:MM a"
                                          )}
                                        </p>
                                        <p>
                                          In Reply to{" "}
                                          <span>{item.postUserFullName}</span>
                                        </p>
                                        <p className="comment-para">
                                          {comment.description}
                                        </p>

                                        <ul className="share-section">
                                          <li
                                            onClick={() =>
                                              handleLike(
                                                item.postId,
                                                comment.id
                                              )
                                            }
                                          >
                                            <span>
                                              <AiOutlineHeart className="me-2" />
                                              {comment.like}
                                            </span>
                                          </li>
                                          <li
                                            onClick={() => {
                                              $(".comment-reply").css(
                                                "display",
                                                "none"
                                              );
                                              $(
                                                `.comment-form-${item.postId}`
                                              ).css("display", "none");
                                              $(
                                                `#comment-form-child${item.postId}`
                                              ).css("display", "block");
                                            }}
                                          >
                                            <BsChatDots className="me-2" />
                                            {comment.commentCount}
                                          </li>
                                          <li>
                                            <BsSend className="me-2" />
                                            Share
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    {/* comment Replies */}
                                    {comment.commentReply &&
                                      comment.commentReply.length > 0 &&
                                      comment.commentReply.map(
                                        (commentReplyItem) => {
                                          return (
                                            <div
                                              className="mini-comment-reply"
                                              style={{ display: "block" }}
                                            >
                                              <div className="mini-group">
                                                <div className="comment-img mini">
                                                  <img
                                                    src={
                                                      commentReplyItem.userImage
                                                        ? mediaUrl +
                                                          commentReplyItem.userImage
                                                        : require("../../images/women.png")
                                                    }
                                                    alt=""
                                                  />
                                                  <div className="comment-name">
                                                    <h6>
                                                      {
                                                        commentReplyItem.userFullName
                                                      }
                                                    </h6>
                                                    <p>
                                                      {moment(
                                                        comment.created_at
                                                      ).format("D MMMM")}{" "}
                                                      at{" "}
                                                      {moment(
                                                        commentReplyItem.created_at
                                                      ).format("H:MM a")}
                                                    </p>
                                                    <p>
                                                      In Reply to{" "}
                                                      <span>
                                                        {
                                                          commentReplyItem
                                                            .replyUsername
                                                            .full_name
                                                        }
                                                      </span>
                                                    </p>
                                                    <p className="comment-para">
                                                      {
                                                        commentReplyItem.description
                                                      }
                                                    </p>

                                                    <ul className="share-section">
                                                      <li
                                                        onClick={() =>
                                                          handleLike(
                                                            item.postId,
                                                            commentReplyItem.id
                                                          )
                                                        }
                                                      >
                                                        <span>
                                                          <AiOutlineHeart className="me-2" />
                                                          {
                                                            commentReplyItem.like
                                                          }
                                                        </span>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}

                                    <form
                                      id={`comment-form-child${item.postId}`}
                                      style={{
                                        display: "none",
                                        position: "relative",
                                      }}
                                      className="comment-reply"
                                      onSubmit={createLikeComment}
                                    >
                                      <input
                                        type="hidden"
                                        value={comment.id}
                                        name="isparent_id"
                                      />
                                      <input
                                        type="hidden"
                                        value={item.postId}
                                        name="post_id"
                                      />
                                      <input
                                        type="text"
                                        id="comment-input"
                                        className="float-end"
                                        name="comment"
                                        placeholder="reply"
                                      />
                                      <button
                                        className="comment-btn-sub1"
                                        type="submit"
                                      >
                                        Send{" "}
                                        <img
                                          src={require("../../images/send.png")}
                                          alt=""
                                        />
                                      </button>
                                    </form>
                                  </div>
                                );
                              })
                            : "No Comments"}
                          <form
                            id={`comment-form`}
                            className={`comment-form-${item.postId}`}
                            onSubmit={createLikeComment}
                          >
                            <input
                              type="hidden"
                              value={item.postId}
                              name="postid"
                            />
                            <input
                              type="text"
                              id="comment-input"
                              className="float-end"
                              name="comment"
                              placeholder="comment"
                            />
                            <button className="comment-btn-sub" type="submit">
                              Send{" "}
                              <img
                                src={require("../../images/send.png")}
                                alt=""
                              />
                            </button>
                          </form>
                        </div>
                      </>
                    );
                  })
                : ""}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Forum;
