import React from "react";
import {
  StandaloneSearchBox,
  LoadScript,
  useLoadScript,
} from "@react-google-maps/api";
import Swal from "sweetalert2";
import { profileUpdateApi } from "../../services/api";
import { mediaUrl } from "../../services/content";
const ProfileTab = ({ activeTab, data, getProfileDataFun }) => {
  const [file, setFile] = React.useState(false);
  const [image, setImage] = React.useState(false);
  const id = JSON.parse(localStorage.getItem("user"))?.id;
  const [searchBox, setSearchBox] = React.useState(null);
  const handleChange = (e) => {
    console.log(e.target.files);
    setImage(e.target.files[0]);
    console.log("image", image);
    setFile(URL.createObjectURL(e.target.files[0]));
  };
  const handlePlaceChanged = () => {
    const [place] = searchBox.getPlaces();
    if (place) {
      console.log(place.formatted_address);
      console.log(place.geometry.location.lat());
      console.log(place.geometry.location.lng());
    }
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    let data;
    if (image) {
      data = {
        id: id,
        image: image,
        full_name: e.target.full_name.value,
        phone: e.target.phone.value,
        location: e.target.location.value,
      };
    } else {
      data = {
        id: id,
        old_image: e.target.old_image.value,
        full_name: e.target.full_name.value,
        phone: e.target.phone.value,
        location: e.target.location.value,
      };
    }
    try {
      console.log("data", data);
      const response = await profileUpdateApi(data);
      console.log("response", response);
      if (response.data.status) {
        Swal.fire("Successfully Updated");
        getProfileDataFun(id);
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  console.log("data", data);
  return (
    <div
      class={`tab-pane fade ${activeTab}`}
      id="pills-home"
      role="tabpanel"
      aria-labelledby="pills-home-tab"
    >
      <div class="tab_profile_upload">
        <form onSubmit={onSubmit}>
          <div class="row">
            <div class="col-lg-4">
              <div class="imagefile">
                <span>
                  <i class="fas fa-camera"></i>
                  <input type="file" name="image" onChange={handleChange} />
                </span>
                <input
                  type="hidden"
                  name="old_image"
                  value={data && data.image}
                />
                <img
                  class="w-100"
                  id="blah"
                  src={`${
                    file ? file : data && data.image && mediaUrl + data.image
                  }`}
                  alt="your image"
                />
              </div>
            </div>

            <div class="col-lg-8">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-label">Full name</label>
                    <input
                      type="text"
                      class="form-control"
                      name="full_name"
                      defaultValue={data && data.full_name}
                      placeholder="Enter first name"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-label">Email</label>
                    <input
                      type="text"
                      class="form-control"
                      name="email"
                      defaultValue={data && data.email}
                      readOnly
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-label">Number</label>
                    <input
                      type="tel"
                      class="form-control"
                      name="phone"
                      defaultValue={data && data.phone}
                      placeholder="Enter number"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <LoadScript
                    googleMapsApiKey={`AIzaSyDA-fY7vuZTLt0xvKABD82EOfHIkeCHFGY`}
                    libraries={["places"]}
                  >
                    <StandaloneSearchBox
                      onLoad={(ref) => setSearchBox(ref)}
                      onPlacesChanged={() => handlePlaceChanged}
                    >
                      <div class="form-group">
                        <label class="form-label">Address</label>
                        <input
                          type="text"
                          class="form-control"
                          name="location"
                          defaultValue={data && data.location}
                          placeholder="Enter address"
                        />
                      </div>
                    </StandaloneSearchBox>
                  </LoadScript>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <button type="submit">Save &amp; Continue</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileTab;
