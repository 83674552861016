import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import logo from "./logo.svg";
// import './App.css';
import "./Custom.css";
import "bootstrap/dist/css/bootstrap.css";
import Signup from "./component/signup";
import Login from "./component/login";
import Home from "./component/home";
import News from "./component/news";
import Event from "./component/events";
import Forum from "./component/forum";
import Sport from "./component/sport";
import TermAndCondition from "./component/term-and-condition";
import PrivacyPolicy from "./component/privacy-policy";
import ContactUs from "./component/contact-us";
import Faq from "./component/faq";
import EventDetails from "./component/events/eventDetails";
import SingleNews from "./component/news/single_news";
import ResetPassword from "./component/reset-password";
import CreatePostForm from "./component/form/createPostForm";
import AuthRoute from "././Authroute";
import DashboardUser from "./component/dashboard";
import TravelBooking from "./component/TravelBooking";
import SportDetails from "./component/sport/sportDetails";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const loggedInUser = JSON.parse(localStorage.getItem("user"));
    if (loggedInUser) {
      setIsAuthenticated(true);
    }
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Home />} exact path="*" />
        <Route element={<Login />} exact path="login" />
        <Route element={<Signup />} exact path="signup" />
        <Route element={<Home />} exact path="home" />
        <Route
          element={
            <AuthRoute element={<News />} isAuthenticated={isAuthenticated} />
          }
          exact
          path="news"
        />
        <Route element={<Event />} exact path="event" />
        <Route
          element={
            <AuthRoute element={<Forum />} isAuthenticated={isAuthenticated} />
          }
          exact
          path="forum"
        />
        <Route
          element={
            <AuthRoute
              element={<TravelBooking />}
              isAuthenticated={isAuthenticated}
            />
          }
          exact
          path="travelBooking"
        />
        {/* <Route element={<AuthRoute element={<Forum />} isAuthenticated={isAuthenticated} />} exact path="forum" /> */}
        {/* <Route element={<AuthRoute element={<Forum />} isAuthenticated={isAuthenticated} />} exact path="forum" /> */}
        <Route element={<Sport />} exact path="sports" />
        <Route element={<TermAndCondition />} exact path="termandcondition" />
        <Route element={<PrivacyPolicy />} exact path="privacypolicy" />
        <Route element={<ContactUs />} exact path="contact" />
        <Route element={<Faq />} exact path="faq" />
        <Route element={<EventDetails />} exact path="event-details" />
        <Route element={<SportDetails />} exact path="sport-details" />
        
        
        <Route
          element={
            <AuthRoute
              element={<SingleNews />}
              isAuthenticated={isAuthenticated}
            />
          }
          exact
          path="single_news"
        />
        <Route element={<ResetPassword />} exact path="reset_password" />
        <Route
          element={
            <AuthRoute
              element={<CreatePostForm />}
              isAuthenticated={isAuthenticated}
            />
          }
          exact
          path="create_post"
        />
        <Route
          element={
            <AuthRoute
              element={<DashboardUser />}
              isAuthenticated={isAuthenticated}
            />
          }
          exact
          path="dashboard"
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
