import React from "react";
import Tab from "./tab";
import Header from "../header";
import Footer from "../footer";
import ProfileTab from "./profileTab";
import SubscriptionTab from "./subscriptionTab";
import VoucherTab from "./voucherTab";
import { getProfileDataApi, dashboardVoucherApi, getSavedEventApi } from "../../services/api";
import EventTab from "./eventTab";
const DashboardUser = () => {
    const [activeTab, setActiveTab] = React.useState("home");
    const [voucherData, setVoucherData] = React.useState([]);
    const [profileData, setProfileData] = React.useState({});
    const [eventData, setEventData] = React.useState([]);
    const id = JSON.parse(localStorage.getItem('user'))?.id;
    React.useEffect(()=>{
         getProfileDataFun(id);
         dashboardVoucherFun(id);
         dashboardEventFun(id);
    }, [setProfileData])
    const getProfileDataFun = async (id) => {
      try {
        const body = {
            id
        }
         const response = await getProfileDataApi(body);
         if(response.data.status) {
             setProfileData(response.data.data);
         }
      } catch (e) {
        console.log("error", e);
      }
    }

    const dashboardVoucherFun = async (id) => {
        const body = {
            userId: id
        }
      try {
        const response = await dashboardVoucherApi(body);
        if(response.data.status) {
            setVoucherData(response.data.data);
        }
      } catch (e) {
        console.log("error", e);
      }
    }

    const dashboardEventFun = async (id) => {
      const body = {
        id
      }
      try {
        const response = await getSavedEventApi(body);
        if(response.data.status) {

          setEventData(response.data.data);
        }
      } catch (e) {
        console.log("error", e)
      }
    }
    return (<>
        <Header />
        <div class="dashnoard_tab">
            <div class="container">

                <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <Tab id={"pills-home-tab"} title={"Profile"} activeTab={activeTab == "home" && 'active'} name={"home"} setActiveTab={setActiveTab} />
                    <Tab id={"pills-profile-tab"} title={"Subscription"} activeTab={activeTab == "profile" && 'active'} name={"profile"} setActiveTab={setActiveTab} />
                    <Tab id={"pills-contact-tab"} title={"Voucher"} activeTab={activeTab == "voucher" && 'active'} name={"voucher"} setActiveTab={setActiveTab} />
                    <Tab id={"pills-testing-tab"} title={"Events"} activeTab={activeTab == "event" && 'active'} name={"event"} setActiveTab={setActiveTab} />
                </ul>

                <div class="tab-content" id="pills-tabContent">

                    {/* <!-- ***** Profile ***** --> */}
                    <ProfileTab activeTab={activeTab == "home" && "show active"} data={profileData} getProfileDataFun={getProfileDataFun} />
                    {/* <!-- ***** Subscription ***** --> */}
                    <SubscriptionTab activeTab={activeTab == "profile" && "show active"} />
                    {/* <!-- ***** Vouture ***** --> */}
                    <VoucherTab activeTab={activeTab == "voucher" && "show active"} voucherData={voucherData} />

                    <EventTab  activeTab={activeTab == "event" && 'show active'} eventData={eventData} dashboardEventFun={dashboardEventFun}  />
                </div>

            </div>
        </div>
        <Footer /></>)
}

export default DashboardUser;