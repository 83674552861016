import moment from "moment";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { BsArrowRightCircle } from "react-icons/bs";
import { addSavedEventApi } from "../../services/api";
import { mediaUrl } from "../../services/content";
const EventTab = ({ activeTab, eventData, dashboardEventFun }) => {
  const userId = JSON.parse(localStorage.getItem("user"))?.id;
  const savedEvent = async (eventId) => {
    let body;
    if (!userId) {
      Swal.fire("Please Login");
    } else {
      body = {
        userId,
        eventId,
      };
      try {
        const response = await addSavedEventApi(body);
        if (response.data.status) {
          Swal.fire(response.data.message);
          dashboardEventFun(userId);
        }
      } catch (e) {
        console.log("error", e);
      }
    }
  };
  return (
    <div
      class={`tab-pane fade ${activeTab}`}
      id="pills-testing"
      role="tabpanel"
      aria-labelledby="pills-testing-tab"
    >
      <section id="upcoming-events">
        <div class="container">
          <div class="row">
            {/* <!-- <h3>Upcoming <span>Events</span></h3> --> */}
            {eventData && eventData.length > 0
              ? eventData.map((item) => {
                  return (
                    <>
                      <div class="col-lg-3 col-sm-6 mb-4">
                        <div class="football-event">
                          <div className="football_img" style={{ backgroundImage: `url(${mediaUrl}${item.thumbnail})` }}>
                            {/* <img
                              class="w-100"
                              src={`${mediaUrl}${item.thumbnail}`}
                              alt=""
                            /> */}
                            <i
                              class="bi bi-heart-fill"
                              onClick={() => savedEvent(item.id)}
                            ></i>
                          </div>
                          <h6>{item.title}</h6>

                          <h5>
                            <span class="me-3">
                              {" "}
                              <img
                                src={require("../../images/location_on.png")}
                                alt=""
                              />
                              {item.location.includes(",")
                                ? `${item.location.split(",")[0]}, ${
                                    item.location.split(",")[1]
                                  }`
                                : item.location}
                            </span>{" "}
                            <span>
                              <img
                                src={require("../../images/calendar_month.png")}
                                alt=""
                              />
                              {moment(item.date).format("D MMMM, YYYY")}
                            </span>
                          </h5>

                          <p>{item.description.substring(0, 100)}</p>

                          <Link
                            class="btn"
                            to={`/event-details/?id=${item.id}`}
                          >
                            Read more <BsArrowRightCircle className="ms-2" />
                          </Link>
                        </div>
                      </div>
                    </>
                  );
                })
              : ""}
          </div>
        </div>
      </section>
    </div>
  );
};

export default EventTab;
