import moment from "moment";
import { mediaUrl } from "../../services/content";

const VoucherTab = ({ activeTab, voucherData }) => {
  return (
    <div
      class={`tab-pane fade ${activeTab}`}
      id="pills-contact"
      role="tabpanel"
      aria-labelledby="pills-contact-tab"
    >
      <div class="vouture">
        <div class="row">
          {voucherData.length > 0
            ? voucherData.map((item) => {
                return (
                  <div class="col-lg-3 col-md-4 col-6">
                    <div class="set_img">
                      <div
                        style={{
                          backgroundImage: `url(${mediaUrl}${item.image})`,
                        }}
                        class="coup_img"
                      ></div>
                      <i class="bi bi-heart-fill"></i>
                      <h2>New</h2>
                      <p>{item.title}</p>

                      <h5>
                        Expire Date:{" "}
                        <span>
                          {moment(item.expiry_date).format("DD/MM/YYYY")}
                        </span>{" "}
                      </h5>
                    </div>
                  </div>
                );
              })
            : "No Saved Vocuchers"}

          {/* <div class="col-lg-3 col-md-4 col-6">
                    <div class="set_img">
                        <div style={{ backgroundImage: `url(https://i.pinimg.com/564x/a3/e7/86/a3e786f546487e3245b343afb76ace94.jpg)` }} class="coup_img">
                        </div>
                        <h2>New</h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis, autem
                            placeat.</p>

                        <h5>Expire Date: <span>20/09/2023</span> </h5>
                    </div>
                </div> */}

          {/* <div class="col-lg-3 col-md-4 col-6">
                    <div class="set_img">
                        <div style={{ backgroundImage: `url(https://pbs.twimg.com/media/Fw9MZ4dagAIvK8M.jpg)` }} class="coup_img">

                        </div>
                        <h2>New</h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis, autem
                            placeat.</p>

                        <h5>Expire Date: <span>20/09/2023</span> </h5>
                    </div>
                </div> */}

          {/* <div class="col-lg-3 col-md-4 col-6">
                    <div class="set_img">
                        <div style={{ backgroundImage: `url(https://bloggerspassion.com/wp-content/uploads/2021/11/cloudways-coupon-code.png)` }} class="coup_img">

                        </div>
                        <h2>New</h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis, autem
                            placeat.</p>

                        <h5>Expire Date: <span>20/09/2023</span> </h5>
                    </div>
                </div> */}

          {/* <div class="col-lg-3 col-md-4 col-6">
                    <div class="set_img">
                        <div style={{ backgroundImage: `url(https://www.pushengage.com/wp-content/uploads/2022/04/Coupon-Marketing-Strategy.png)` }} class="coup_img">

                        </div>
                        <h2>New</h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis, autem
                            placeat.</p>

                        <h5>Expire Date: <span>20/09/2023</span> </h5>
                    </div>
                </div> */}

          {/* <div class="col-lg-3 col-md-4 col-6">
                    <div class="set_img">
                        <div style={{ backgroundImage: `url(https://i.pinimg.com/564x/a3/e7/86/a3e786f546487e3245b343afb76ace94.jpg)` }} class="coup_img">
                        </div>
                        <h2>New</h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis, autem
                            placeat.</p>

                        <h5>Expire Date: <span>20/09/2023</span> </h5>
                    </div>
                </div> */}

          {/* <div class="col-lg-3 col-md-4 col-6">
                    <div class="set_img">
                        <div style={{ backgroundImage: `url(https://pbs.twimg.com/media/Fw9MZ4dagAIvK8M.jpg)` }} class="coup_img">

                        </div>
                        <h2>New</h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis, autem
                            placeat.</p>

                        <h5>Expire Date: <span>20/09/2023</span> </h5>
                    </div>
                </div>

                <div class="col-lg-3 col-md-4 col-6">
                    <div class="set_img">
                        <div style={{ backgroundImage: `url(https://bloggerspassion.com/wp-content/uploads/2021/11/cloudways-coupon-code.png)` }} class="coup_img">

                        </div>
                        <h2>New</h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis, autem
                            placeat.</p>

                        <h5>Expire Date: <span>20/09/2023</span> </h5>
                    </div>
                </div> */}
        </div>
      </div>
    </div>
  );
};

export default VoucherTab;
