import React from "react";
import OwlCarousel from "react-owl-carousel3";
import Swal from "sweetalert2";
import { getAllVoucherApi, savedVoucherApi } from "../../services/api";
import BoostrapModel from "../Tools";
import VoucherDetails from "./voucherDetails";
import { mediaUrl } from "../../services/content";
const VoucherList = ({ id }) => {
  const [data, setData] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const [idd, setIdd] = React.useState("");
  const userId = JSON.parse(localStorage.getItem("user"))?.id;
  React.useEffect(() => {
    getAllVoucherFun(id);
  }, []);
  const getAllVoucherFun = async (id) => {
    try {
      const response = await getAllVoucherApi({ id });
      if (response.data.status) {
        setData(response.data.data);
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const savedVocucherFun = async (voucherId) => {
    const body = {
      userId: userId,
      voucherId: voucherId,
    };
    console.log("body", body);
    try {
      const response = await savedVoucherApi(body);
      if (response.data.status) {
        Swal.fire(response.data.message);
        getAllVoucherFun(id);
      }
    } catch (e) {
      console.log("error", e);
    }
  };
  return (
    <>
      {" "}
      <section id="voucher">
        <div className="container">
          <div className="row">
            {data && data.length > 0
              ? data.map((item) => {
                  return (
                    <div class="col-sm-6 col-lg-4 col-xxl-3">
                      <div class="item_set">
                        <img
                          onClick={() => {
                            setIdd(item.id);
                            setShow(!show);
                          }}
                          src={`${mediaUrl}${item.image}`}
                          alt=""
                        />
                        <h4 style={{ textAlign: "center" }}>{item.title}</h4>
                        <i
                          class={`bi bi-heart${item.exixt ? "-fill" : ""}`}
                          onClick={() => savedVocucherFun(item.id)}
                        ></i>
                      </div>
                    </div>
                  );
                })
              : ""}

            {/* <div className="col-12">

                    <h3>All Vouchers</h3>

                    <div className="home-demo">
                        <OwlCarousel
                            className="owl-theme"
                            loop margin={10} nav={false} autoplay={true}
                        >
                            {data && data.length > 0 ? data.map((item) => {
                                return (
                                    <div className="item" onClick={() => {
                                        setShow(!show);
                                        setIdd(item.id);
                                    }}>
                                        <div className="voucher-img" style={{
                                            border: "1px solid",
                                            textAlign: "-webkit-center",
                                            borderRadius: "72px"
                                        }}>
                                            <img style={{ width: "100px" }} src={`https://digittrix-staging.com/staging/sportsfan/OldAdmin/sportsfanworld/public/uploads/${item.image}`} alt="" />
                                        </div>
                                        <div className="voucher-title">
                                            <h4 style={{
                                                textAlign: "center"
                                            }}>{item.title}</h4>
                                        </div>
                                    </div>
                                )

                            }) : ""}
                        </OwlCarousel>
                    </div>
                </div> */}
          </div>
        </div>
        <BoostrapModel
          show={show}
          onHide={() => setShow(!show)}
          component={<VoucherDetails id={idd} />}
          heading={"Voucher Details"}
          title={true}
        />
      </section>
    </>
  );
};

export default VoucherList;
