const SubscriptionTab = ({activeTab}) => {
    return (<div class={`tab-pane fade ${activeTab}`} id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">

        <div class="subscription">
            <div class="row">
                <div class="col-lg-4 col-md-6 mt-3">
                    <div class="sub_box">
                        <img src="images/basketball-player 2.png" alt="" />
                        <h3>Basic Plane</h3>
                        <h4>100$</h4>
                        <p>Validity : 3 months</p>
                        <a href="#" class="btn">Choose Plane</a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 mt-3">
                    <div class="sub_box">
                        <img src="images/skier.png" alt="" />
                        <h3>Standard Plane</h3>
                        <h4>250$</h4>
                        <p>Validity : 6 months</p>
                        <a href="#" class="btn">Choose Plane</a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 mt-3">
                    <div class="sub_box">
                        <img src="images/ice-hockey (1) 1.png" alt="" />
                        <h3>Premium Plane</h3>
                        <h4>300$</h4>
                        <p>Validity : 1 year</p>
                        <a href="#" class="btn">Choose Plane</a>
                    </div>
                </div>
            </div>
        </div>

    </div>)
}

export default SubscriptionTab;