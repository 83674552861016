import React from "react";
import { Link } from "react-router-dom";
import profileBg from "../../images/profile-bg.png";
import Header from "../header";
import Footer from "../footer";
import { allnews, categoryWithEventCount } from "../../services/api";
import moment from "moment";
import { mediaUrl } from "../../services/content";

const News = () => {
  const [allNews, setAllNews] = React.useState([]);
  const [category, setCategory] = React.useState([]);
  React.useEffect(() => {
    getAllNews();
    getcategorynews();
  }, [setAllNews]);

  const getAllNews = async () => {
    try {
      const result = await allnews();
      setAllNews(result);
    } catch (e) {
      console.log("error", e.message);
    }
  };

  const getcategorynews = async () => {
    try {
      const response = await categoryWithEventCount();
      setCategory(response?.data.data);
    } catch (e) {
      console.log("error", e.message);
    }
  };

  const list = category;

  const updatedList = Object.values(
    list.reduce((map, el) => {
      map[el.categorytitle]
        ? map[el.categorytitle].count++
        : (map[el.categorytitle] = { ...el, count: el.category_id ? 1 : 0 });
      return map;
    }, {})
  );

  return (
    <>
      <Header />
      <section
        style={{ backgroundImage: `url(${profileBg})` }}
        id="blog-banner"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>News</h2>
              <ul className="bcrumbs">
                <li>
                  <Link to="/home">
                    <i className="fa fa-home"></i>
                  </Link>
                </li>
                <li>News</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section id="blog-news">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="row">
                {/* <div className="col-md-6">
                                    <div className="bolg-img">
                                    <Link to="/single_news">
                                            <img className="w-100" src={require("../../images/upcoming-1.png")} alt="" />
                                                <h3>Football Event</h3>
                                                <p>It is accompanied by a case that can contain up to three different diffusers and
                                                    can
                                                    be used for dry storage of loose tea. The perfect way to enjoy brewing tea on
                                                    low
                                                    hanging fruit to identify…</p>
                                                <h6>Post Date: <span>August 4, 2020</span> </h6>

                                        </Link>
                                    </div>
                                </div> */}
                {allNews.length > 0
                  ? allNews?.map((item) => {
                      return (
                        <>
                          <div className="col-sm-6">
                            <div className="bolg-img">
                              <Link to={`/single_news/?id=${item.id}`}>
                                <div
                                  style={{
                                    backgroundImage: `url(${mediaUrl}${item.image})`,
                                  }}
                                  className="new_image"
                                ></div>
                                {/* <img className="w-100" style={{ borderRadius: "10px"}} src={`https://digittrix-staging.com/staging/sportsfan/OldAdmin/sportsfanworld/public/uploads/${item.image}`} alt=""/> */}
                                <h3>{item?.title}</h3>
                                <p>{item?.description}</p>
                                <h6>
                                  Post Date:{" "}
                                  <span>
                                    {moment(item.created_at).format(
                                      "MMMM D, YYYY"
                                    )}
                                  </span>{" "}
                                </h6>
                              </Link>
                            </div>
                          </div>
                        </>
                      );
                    })
                  : ""}
              </div>
            </div>

            <div className="col-lg-4 mt-4">
              <div className="news-search">
                <h5>Search</h5>
                <form className="position-relative">
                  <input type="text" placeholder="Search..." />
                  <button className="news-search-btn" type="submit">
                    {" "}
                    <img src="../../images/search.png" alt="" />{" "}
                  </button>
                </form>
              </div>

              <div className="popular-post">
                <h5>Popular Post</h5>
                <a href="#">
                  <img
                    className="w-100"
                    src={require("../../images/upcoming-3.png")}
                    alt=""
                  />
                  <p>Football Event</p>
                  <span>August 4, 2020</span>
                </a>

                <div className="b_single pt-3 pb-3 mb-5">
                  <div className="b_single__thumb">
                    <img
                      className="w-100"
                      src={require("../../images/profile-man.png")}
                      alt=""
                    />
                  </div>
                  <div className="b_single__content">
                    <a href="#">Top 10 Outdoor Items For Your </a>
                    <span>August 4, 2020</span>
                  </div>
                </div>

                <div className="b_single pt-3 pb-3 mb-5">
                  <div className="b_single__thumb">
                    <img
                      className="w-100"
                      src={require("../../images/profile-man.png")}
                      alt=""
                    />
                  </div>
                  <div className="b_single__content">
                    <a href="#">Top 10 Outdoor Items For Your </a>
                    <span>August 4, 2020</span>
                  </div>
                </div>

                <div className="b_single pt-3 pb-3 mb-5">
                  <div className="b_single__thumb">
                    <img
                      className="w-100"
                      src={require("../../images/profile-man.png")}
                      alt=""
                    />
                  </div>
                  <div className="b_single__content">
                    <a href="#">Top 10 Outdoor Items For Your </a>
                    <span>August 4, 2020</span>
                  </div>
                </div>
              </div>

              <div className="category-post">
                <h5>Categories </h5>
                <ul>
                  {updatedList.length > 0
                    ? updatedList.map((item) => {
                        return (
                          <li>
                            {" "}
                            <a href="#">
                              {item.categorytitle}
                              <span>({item.count})</span>
                            </a>
                          </li>
                        );
                      })
                    : ""}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default News;
