import { useEffect, useState } from "react";
import { BsArrowRightCircle } from "react-icons/bs";
import moment from "moment";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import {
  upcomingevent,
  eventsearchfilter,
  addSavedEventApi,
} from "../../services/api";
import EventBanner from "../../images/home-banner.png";
import Footer from "../footer";
import Header from "../header";
import { mediaUrl } from "../../services/content";

const Event = () => {
  const [eventData, setEventData] = useState([]);
  const [title, setTitle] = useState("");
  const userId = JSON.parse(localStorage.getItem("user"))?.id;
  useEffect(() => {
    allEvents();
  }, [setEventData]);
  const allEvents = async () => {
    try {
      const response = await upcomingevent();
      setEventData(response);
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleChange = async (event) => {
    const title = event.target.value;
    const result = await eventsearchfilter(title);
    setEventData(result.data.data);
  };

  const savedEvent = async (eventId) => {
    let body;
    if (!userId) {
      Swal.fire("Please Login");
    } else {
      body = {
        userId,
        eventId,
      };
      try {
        const response = await addSavedEventApi(body);
        if (response.data.status) {
          Swal.fire(response.data.message);
        }
      } catch (e) {
        console.log("error", e);
      }
    }
  };
  return (
    <>
      <Header />
      {/* <!-- ///////////-----Comment Form----Section---///////////// --> */}

      <section
        style={{ backgroundImage: `url(${EventBanner})` }}
        id="event-banner"
      >
        <div class="container position-relative">
          <div class="row">
            <div class="home-search post event">
              <form>
                <div class="row">
                  <div class="col-lg-12 mb-2 mt-2">
                    <div class="home-submit">
                      <form>
                        <input
                          id="home-search"
                          type="text"
                          name="search"
                          onChange={handleChange}
                          placeholder="Search.."
                        />
                        <button class="home-search-btn" type="submit">
                          <img
                            src={require("../../images/search.png")}
                            alt=""
                          />
                        </button>
                      </form>
                    </div>
                  </div>

                  {/* <!-- <div class="col-lg-4 mb-3 mt-3">
    
                                    <p class="filter-section post"><a href="#"><i class="bi bi-plus-circle"></i>Create
                                            Post</a></p>
    
                                </div> --> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* 
    <!-- ///////////-----Upcoming Events----Section---///////////// --> */}

      <section id="upcoming-events">
        <div class="container">
          <div class="row">
            {/* <!-- <h3>Upcoming <span>Events</span></h3> --> */}
            {eventData && eventData.length > 0
              ? eventData.map((item) => {
                  return (
                    <>
                      <div class="col-lg-3 col-sm-6 mb-4">
                        <div class="football-event">
                          <div className="football_img" style={{ backgroundImage: `url(${mediaUrl}${item.thumbnail}` }}>
                            {/* <img
                              class="w-100"
                              src={`${mediaUrl}${item.thumbnail}`}
                              alt=""
                            /> */}
                            <i
                              class="bi bi-heart"
                              onClick={() => savedEvent(item.id)}
                            ></i>
                          </div>
                          <h6>{item.title}</h6>

                          <h5>
                            <span class="me-3">
                              {" "}
                              <img
                                src={require("../../images/location_on.png")}
                                alt=""
                              />
                              {item.location.includes(",")
                                ? `${item.location.split(",")[0]}, ${
                                    item.location.split(",")[1]
                                  }`
                                : item.location}
                            </span>{" "}
                            <span>
                              <img
                                src={require("../../images/calendar_month.png")}
                                alt=""
                              />
                              {moment(item.date).format("D MMMM, YYYY")}
                            </span>
                          </h5>

                          <p>{item.description.substring(0, 100)}</p>

                          <Link
                            class="btn"
                            to={`/event-details/?id=${item.id}`}
                          >
                            Read more <BsArrowRightCircle className="ms-2" />
                          </Link>
                        </div>
                      </div>
                    </>
                  );
                })
              : ""}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Event;
