import React from "react";
import OwlCarousel from "react-owl-carousel3";
import { mediaUrl } from "../../services/content";
const EventNearBy = ({ event }) => {
  let responsive = {
    0: {
      items: 1,
    },
    450: {
      items: 2,
    },
    600: {
      items: 3,
    },
    992: {
      items: 4,
    },
  };
  return (
    <>
      <section id="Event-near">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3>
                Events <span>Near By</span>{" "}
              </h3>

              <div className="home-demo">
                <OwlCarousel
                  className="owl-theme"
                  loop
                  items={4}
                  navText={["<", ">"]}
                  margin={10}
                  nav={false}
                  autoplay={true}
                  responsive={responsive}
                >
                  {event && event.length > 0
                    ? event.map((item) => {
                        return (
                          <div className="item">
                            <div className="event-img" style={{ backgroundImage: `url(${mediaUrl}${item.thumbnail})` }}>
                              {/* <img
                                src={`${mediaUrl}${item.thumbnail}`}
                                alt=""
                              /> */}
                            </div>
                          </div>
                        );
                      })
                    : "No Events Near By"}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EventNearBy;
