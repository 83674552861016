import Header from "../header";
import Footer from "../footer";
import termAndConditionImage from '../../images/profile-bg.png';
import { Link } from "react-router-dom";

const termAndCondition = () => {
    return (
        <>
            <Header />
            {/* <!-- ///////////-----blog-banner----Section---///////////// --> */}

            <section style={{ backgroundImage: `url(${termAndConditionImage})` }} id="blog-banner">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h2>Terms & Conditions</h2>
                            <ul class="bcrumbs">
                                <li><Link href="index.html"><i class="fa fa-home"></i></Link></li>
                                <li>Terms & Conditions</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- ///////////-----Terms & Conditions----Section---///////////// --> */}


            <section id="privacy-policy">
                <div class="container">
                    <div class="row">
                        <div class="col-12">

                            <h3>1. Terms</h3>

                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit, dolorum ullam! Corrupti
                                quisquam consequuntur aliquam optio ut rem. Vitae, eum assumenda. Eos blanditiis, quod minima
                                soluta porro beatae distinctio quos.</p>

                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit, dolorum ullam! Corrupti
                                quisquam consequuntur aliquam optio ut rem. Vitae, eum assumenda. Eos blanditiis, quod minima
                                soluta porro beatae distinctio quos.</p>

                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit, dolorum ullam! Corrupti
                                quisquam consequuntur aliquam optio ut rem. Vitae, eum assumenda. Eos blanditiis, quod minima
                                soluta porro beatae distinctio quos.</p>

                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit, dolorum ullam! Corrupti
                                quisquam consequuntur aliquam optio ut rem. Vitae, eum assumenda. Eos blanditiis, quod minima
                                soluta porro beatae distinctio quos.</p>

                            <h3>2. Use License</h3>

                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit, dolorum ullam! Corrupti
                                quisquam consequuntur aliquam optio ut rem. Vitae, eum assumenda. Eos blanditiis, quod minima
                                soluta porro beatae distinctio quos. amet, consectetur adipisicing elit. Reprehenderit, dolorum
                                ullam! Corrupti
                                quisquam consequuntur aliquam optio ut rem. Vitae, eum assumenda.</p>

                            <p>You must not:</p>

                            <ul>
                                <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis, omnis fuga. </li>
                                <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis, omnis fuga. </li>
                                <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis, omnis fuga. </li>
                                <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis, omnis fuga. </li>
                                <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis, omnis fuga. </li>
                            </ul>

                            <h3>3. Changes</h3>

                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit, dolorum ullam! Corrupti
                                quisquam consequuntur aliquam optio ut rem. Vitae, eum assumenda. Eos blanditiis, quod minima
                                soluta porro beatae distinctio quos.</p>

                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit, dolorum ullam! Corrupti
                                quisquam consequuntur aliquam optio ut rem. Vitae, eum assumenda. Eos blanditiis, quod minima
                                soluta porro beatae distinctio quos.</p>

                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit, dolorum ullam! Corrupti
                                quisquam consequuntur aliquam optio ut rem. Vitae, eum assumenda. Eos blanditiis, quod minima
                                soluta porro beatae distinctio quos.</p>

                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit, dolorum ullam! Corrupti
                                quisquam consequuntur aliquam optio ut rem. Vitae, eum assumenda. Eos blanditiis, quod minima
                                soluta porro beatae distinctio quos.</p>

                            <ul>
                                <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis, omnis fuga. </li>
                                <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis, omnis fuga. </li>
                                <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis, omnis fuga. </li>
                                <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis, omnis fuga. </li>
                                <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis, omnis fuga. </li>
                            </ul>


                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default termAndCondition;