import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import axios from 'axios';
import signupBackground from '../../images/signup-img.png';
import Header from '../header';
import Footer from '../footer';
import { allSubscription } from '../../services/api';


const schema = yup.object().shape({
    full_name: yup.string().required(),
    email: yup.string().required().email(),
    password: yup.string().required().min(8),
    phone: yup.string().required().min(10).max(10)
}).required();

const Signup = () => {
    const [cpassword, setCpassword] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });
    const [subscriptionData, setSubscripitonData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        allSubscriptionFun();
    }, [setSubscripitonData]);

    const onSubmit = (data) => {
        if (data.password == data.confirmPassword) {
            delete data.confirmPassword;
            data.devicetype = "web";
            axios.post('https://digittrix-staging.com/staging/sportsfan/OldAdmin/sportsfanworld/api/user_signup', data).then((response) => {
                console.log("response", response);
                if (response.data.status == "true") {
                    alert(response.data.message);
                    localStorage.setItem('user', JSON.stringify(response.data.New_user))
                    navigate("/home");
                }
                else {
                    alert(response.data.message);
                }
            }).catch((err) => {
                console.log("err", err);
            })
        }
        else {
            setCpassword(true);
        }
    }

    const allSubscriptionFun = async () => {
        try {
            const response = await allSubscription();
            if (response.data.status) {
                setSubscripitonData(response.data.data);
            }
        }
        catch (e) {
            console.log("error", e);
        }
    }
    console.log("cpassword", cpassword);
    console.log("error", errors);
    return (
        <>
            <Header />
            <section id="login">
                <div class="container">
                    <div class="row g-0 shadow-border">

                        <div class="col-lg-6 col-md-12">

                            <div style={{ backgroundImage: `url(${signupBackground})` }} class="signup-banner">

                                <h2>As SFW+ User </h2>

                                <h5>Key Features</h5>

                                <ul>

                                    <li>
                                        Lorem ipsum dolor sit amet consectetur.
                                    </li>

                                    <li>
                                        Lorem ipsum dolor sit amet consectetur.
                                    </li>

                                    <li>
                                        Lorem ipsum dolor sit amet consectetur.
                                    </li>

                                    <li>
                                        Lorem ipsum dolor sit amet consectetur.
                                    </li>

                                    <li>
                                        Lorem ipsum dolor sit amet consectetur.
                                    </li>

                                    <li>
                                        Lorem ipsum dolor sit amet consectetur.
                                    </li>

                                    <li>
                                        Lorem ipsum dolor sit amet consectetur.
                                    </li>

                                    <li>
                                        Lorem ipsum dolor sit amet consectetur.
                                    </li>

                                    <li>
                                        Lorem ipsum dolor sit amet consectetur.
                                    </li>
                                    <li>
                                        Lorem ipsum dolor sit amet consectetur.
                                    </li>
                                    <li>
                                        Lorem ipsum dolor sit amet consectetur.
                                    </li>

                                </ul>

                            </div>
                            {/* <!-- <img class="w-100" src="images/signup-img.png" alt=""> --> */}
                        </div>

                        <div class="col-lg-6 col-md-12 mt-4">
                            <div class="login-heading">
                                <h3>Create <span style={{ color: "#871614" }}>New Account</span></h3>


                                <form onSubmit={handleSubmit(onSubmit)}>

                                    <div class="mb-3 mt-4">
                                        <label for="exampleInputEmail1" class="form-label">Name</label>
                                        <input type="text" class="form-control" {...register('full_name')} id="nameInput" aria-describedby="emailHelp" />
                                        {errors.full_name && <p style={{ color: "red" }}>{errors.full_name.message}</p>}
                                    </div>

                                    <div class="mb-3">
                                        <label for="exampleInputEmail1" class="form-label">Email</label>
                                        <input type="email" class="form-control" {...register('email', { required: true })} id="exampleInputEmail1"
                                            aria-describedby="emailHelp" />
                                        {errors.email && <p style={{ color: "red" }}>{errors.email.message}</p>}
                                    </div>

                                    <div class="mb-3">
                                        <label for="exampleInputEmail1" class="form-label">Phone number</label>
                                        <input type="number" class="form-control" {...register('phone', { required: true })} id="exampleInputEmail1"
                                            aria-describedby="emailHelp" />
                                        {errors.phone && <p style={{ color: "red" }}>{errors.phone.message}</p>}
                                    </div>


                                    <div class="mb-3">
                                        <label for="exampleInputPassword1" class="form-label">Password</label>
                                        <input type="password" class="form-control" {...register('password')} id="exampleInputPassword1" />
                                        {errors.password && <p style={{ color: "red" }}>{errors.password.message}</p>}
                                    </div>

                                    <div class="mb-3">
                                        <label for="exampleInputPassword1" class="form-label">Confirm Password</label>
                                        <input type="password" class="form-control" {...register('confirmPassword')} id="exampleInputPassword1" />
                                        {cpassword ? <p style={{ color: "red" }}>Confirm password has not matched</p> : ""}
                                    </div>

                                    <div class="mb-3 form-check">
                                        <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                                        <label class="form-check-label" for="exampleCheck1">I agree to the <Link to="/termandcondition">Terms of
                                            User </Link> and <Link to="/privacypolicy">Privacy Policy</Link></label>
                                    </div>

                                    <div class="mb-3 form-check p-0 text-center">
                                        <label class="form-check-label" for="exampleCheck2"><a class="sfw-btn d-inline-block"> Become a SFW+ member</a> </label>
                                    </div>
                                    <div class="row">
                                        {subscriptionData && subscriptionData.length > 0 && subscriptionData.map((i) => {
                                           {console.log("item", i)}
                                           return ( <div class="col-sm-6">
                                            <div class="set" >
                                                <img src="../images/Plan1.png" />
                                                <h3>{i.subscription_type}</h3>
                                                <h2>{i.Price}</h2>
                                            </div>
                                        </div>)
                                        })}
                                    </div>

                                    {/* <div class="col-6">
                                        <div class="set" >
                                            <img src="../images/Plan1.png" />
                                            <h3>Monthly</h3>
                                            <h2>$ 20.00</h2>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="set">
                                            <img src="images/Plan2.png" />
                                            <h3>Annual</h3>
                                            <h2>$ 26.50</h2>
                                        </div>
                                    </div> */}

                                    <button type="submit" class="btn btn-primary mt-3">Sign Up</button>
                                    <div id="emailHelp" class="form-text">Have a account? <Link to="/login">Login</Link> </div>
                                </form>

                            </div>
                        </div>


                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Signup;