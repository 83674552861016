import { AiFillHome } from 'react-icons/ai';
import { BsFillEnvelopeFill, BsFillTelephoneFill, BsFillGeoAltFill } from 'react-icons/bs';
import Header from "../header";
import Footer from "../footer";
import contactBackground from '../../images/profile-bg.png';
import { Link } from 'react-router-dom';

const contactUs = () => {
    return (
        <>
            <Header />
            {/* <!-- ///////////-----blog-banner----Section---///////////// --> */}

            <section style={{backgroundImage:`url(${contactBackground})`}} id="blog-banner">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h2>Contact Us</h2>
                            <ul class="bcrumbs">
                                <li><Link to="/home"><AiFillHome style={{color: "white"}} /></Link></li>
                                <li>Contact Us</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- ///////////-----Contact Us----Section---///////////// --> */}

            <section id="contact-us">
                <div class="container">
                    <div class="row">

                        <div class="col-xxl-5 col-xl-6 col-lg-6">

                            <h4>Contact With Us</h4>
                            <h1>Speak with our Expert.</h1>

                            <div class="single-contact-info d-flex align-items-center">
                                <div class="contact-info-icon">
                                    <a href="#"><BsFillTelephoneFill /></a>
                                </div>
                                <div class="contact-info-text">
                                    <span>Call Anytime</span>
                                    <h5><a href="tell:926668880000">92 666 888 0000</a></h5>
                                </div>
                            </div>

                            <div class="single-contact-info d-flex align-items-center">
                                <div class="contact-info-icon">
                                    <a href="#"><BsFillEnvelopeFill /></a>
                                </div>
                                <div class="contact-info-text">
                                    <span>SEND EMAIL</span>
                                    <h5><a href="mailto:example@company.com">Example@company.com</a></h5>
                                </div>
                            </div>

                            <div class="single-contact-info d-flex align-items-center">
                                <div class="contact-info-icon">
                                    <a href="#"><BsFillGeoAltFill /></a>
                                </div>
                                <div class="contact-info-text">
                                    <span>VISIT OFFICE</span>
                                    <h5><a href="#">550 New York, United States</a></h5>
                                </div>
                            </div>

                        </div>

                        <div class="col-xxl-7 col-xl-6 col-lg-6">

                            <div class="contact-form">
                                <form>

                                    <div class="row">

                                        <div class="col-md-6 mb-3">
                                            <input type="text" placeholder="Name" />
                                        </div>

                                        <div class="col-md-6 mb-3">
                                            <input type="email" placeholder="Email" />
                                        </div>

                                        <div class="col-md-6 mb-3">
                                            <input type="tel" placeholder="Phone" />
                                        </div>

                                        <div class="col-md-6 mb-3">
                                            <input type="text" placeholder="Subject" />
                                        </div>

                                        <div class="col-md-12 mb-3">
                                            <textarea name="" id="" placeholder="Your Message"></textarea>
                                        </div>

                                        <div class="col-md-12 mb-3">
                                            <input class="form-check-input" type="checkbox" value="" id="contact-check" />
                                                <label class="contact-check-label">
                                                    I agree to the Terms of User and Privacy Policy
                                                </label>
                                        </div>

                                        <div class="col-md-12">
                                            <button class="contact-btn" type="submit">Send Message</button>
                                        </div>

                                    </div>

                                </form>
                            </div>
                        </div>

                    </div>

                </div>
            </section>

            <section id="contact-map">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d448196.05311984906!2d76.81304084547108!3d28.643795378541427!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd5b347eb62d%3A0x37205b715389640!2sDelhi!5e0!3m2!1sen!2sin!4v1681297439325!5m2!1sen!2sin"
                                height="450" style={{border:"0"}} allowfullscreen="" loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </section>


            <Footer />
        </>
    )
}

export default contactUs;