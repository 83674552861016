import React from "react";
import OwlCarousel from "react-owl-carousel3";
import { Link } from "react-router-dom";
import axios from "axios";
import { MyContext } from "../../MyContext";
import { getallcategory } from "../../services/api";
import { mediaUrl } from "../../services/content";

const Category = () => {
  const [data, setData] = React.useState([]);
  const [activeCategory, setActiveCategory] = React.useState('');
  const sport =
    window.location.href == "http://localhost:3000/sports" ? true : false;
  const { text, setText } = React.useContext(MyContext);

  React.useEffect(() => {
    allCategory();
  }, [setData]);

  const allCategory = async () => {
    const result = await getallcategory();
    if (result.data.status) {
      setData(result.data.data);
    }
  };

  let responsive = {
    0: {
      items: 2,
    },
    600: {
      items: 4,
    },
    1000: {
      items: 8,
    },
  };

  return (
    <>
      <section id="first-carousel">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {sport ? (
                <div class="category-heading">
                  <h3>
                    {text[0]?.categoryTitle
                      ? text[0].categoryTitle
                      : "Football"}
                  </h3>
                  <from class="category-sr-btn">
                    <input
                      id="category-search"
                      type="text"
                      name="search"
                      placeholder="Search.."
                    />
                    <button type="submit">
                      {" "}
                      <img
                        src={require("../../images/search.png")}
                        alt=""
                      />{" "}
                    </button>
                  </from>
                </div>
              ) : (
                ""
              )}
              <div className="home-demo">
                {/* <div className="owl-carousel owl-carouselfan owl-theme"> */}
                <OwlCarousel
                  className="owl-theme"
                  loop
                  items={8}
                  margin={10}
                  nav
                  responsive={responsive}
                >
                  {data && data.length > 0
                    ? data.map((item, i) => {
                        // console.log("iteem---->", item);
                        return (
                          <div className="item">
                            <Link
                              to={`/sports/?id=${item.id}`}
                              onClick={() =>
                                setText([
                                  {
                                    categoryId: item.id,
                                    categoryTitle: item.title,
                                  },
                                ])
                              }
                            >
                              <div className={`car-img ${activeCategory ? activeCategory == item.title && 'active' : i==0 && 'active'}`} onClick={() => setActiveCategory(item.title)}>
                                <img src={`${mediaUrl}${item.icon}`} alt="" />
                              </div>
                              <h6>{item.title}</h6>
                            </Link>
                          </div>
                        );
                      })
                    : ""}
                </OwlCarousel>

                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Category;
