import Header from "../header";
import Footer from "../footer";
import { Link } from "react-router-dom";

const resetPassword = () => {
    return (
        <>
            <Header />
            <section id="login">
                <div class="container">
                    <div class="row g-0 shadow-border">

                        <div class="col-lg-6 col-md-12">
                            <img class="w-100" src={require("../../images/reset-img.jpg")} alt="" />
                        </div>

                        <div class="col-lg-6 col-md-12 mt-4">
                            <div class="login-heading mt-5">
                                <h3>Reset <span style={{ color: "#871614" }}>Password</span></h3>
                                <p class="reset-para">Strong passwords include numbers,letters and punctution marks.</p>


                                <form >

                                    <div class="mb-3">
                                        <label for="exampleInputPassword1" class="form-label">Enter new password</label>
                                        <input type="password" class="form-control" id="exampleInputPassword1" />
                                    </div>

                                    <div class="mb-3">
                                        <label for="exampleInputPassword1" class="form-label">Confirm new password</label>
                                        <input type="password" class="form-control" id="exampleInputPassword1" />
                                    </div>

                                    <button type="submit" class="btn btn-primary">Reset Password</button>
                                    <div id="emailHelp" class="form-text">Back to <Link to="/login">Login</Link> </div>
                                </form>

                            </div>
                        </div>


                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default resetPassword;
